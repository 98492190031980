<template>
  <app-section-container
    sectionId="multigenerational-households"
    sectionTitle="Multigenerational Households"
    trend="A large majority of adults (83%) live in households consisting of two or more people. “Grandfamily-ing,” family caregiving for older relatives, and adult children living at home are significant trends."
  >
    <!-- Section content -->
    <div class="section-content">
      <p>
        Over half of all adults (52%) live in a multigenerational household—either with children or a parent. Over a third (35%) have children under the age of 18 living at home. Many of these adults living with minor children also have children over age 18 (25%), parents (15%), or another adult relative or friend (33%) living in their household.
      </p>

      <p>
        <a href="https://www.aarp.org/ppi/info-2020/caregiving-in-the-united-states.html" target="_blank" rel="noopener noreferrer">One in five Americans is a family caregiver</a>, and caregivers often have unique needs for their homes:
      </p>

      <ul>
        <li>40% care for someone living in their home.</li>
        <li>38% care for someone living on their own. Of those, 49% are concerned about the ability of the person they are caring for to continue to live on their own.</li>
        <li>59% believe it is important to have a variety of housing types to fit people’s needs as they age, and 53% believe it is important to have homes that are more accessible and include features like no-step entry, wide doors, and first-floor bedrooms and bathrooms.</li>
        <li>65% place a high importance on home repair services for low-income and older adults, and 77% place a high importance on having affordable, trustworthy home modification contractors.</li>
      </ul>
    </div>
  </app-section-container>
</template>

<script>
import AppSectionContainer from '@/components/SectionContainer.vue';

export default {
  name: 'Section 2',
  components: {
    AppSectionContainer,
  },
}
</script>

<style lang="postcss" scoped>

</style>