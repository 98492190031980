<template>
  <div class="example-wrapper">
    <div class="example">
      <h1>
        <span v-if="exampleData.type">{{ exampleData.type }}: </span>{{ exampleData.community }}
      </h1>

      <!-- Text -->
      <p v-html="exampleData.summary"></p>

      <!-- Video -->
      <div 
        v-if="exampleData.video"
        class="video"
      >
        <iframe 
          class="brightcove-video"
          :src='`https://players.brightcove.net/3772599298001/ryhhcDoG_default/index.html?videoId=${exampleData.video}`' 
          allowfullscreen frameborder=0
        ></iframe>
      </div>

      <!-- Image -->
      <div
        v-if="exampleData.image"
      >
        <img 
          :src="exampleData.image"
          :alt="exampleData.alt"
          loading="lazy"
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Example",
  props: {
    exampleData: {
      type: Object,
      required: true,
    }
  }
}
</script>

<style lang="postcss" scoped>
/* Single orphans */
.example-wrapper {
  @apply
    flex
    justify-around;

  &:last-child:nth-child(2n - 1) {
    grid-column: span 2;

    .example {
      @apply
        m-4;

      @screen lg {
        @apply                  
          w-1/2;
      }
    }
  }

  .example {
    @apply
      m-4
      py-4
      px-8
      bg-aarp-pink;

    :deep() {
      a {
        @apply
          border-b
          border-black;
      
        transition: border-color 300ms;

        &:hover {
          @apply
            border-transparent;
        }
      }
    }

    h1 {
      @apply
        pb-4
        mb-4
        border-b
        border-black
        text-center
        text-xl
        font-bold;
    }

    ul {
      @apply 
        list-outside 
        list-disc 
        pl-4;

      li {
        @apply
          mb-4;

        &:last-child {
          @apply
            mb-0;
        }
      }
    }

    .brightcove-video {
      @apply
        mt-4
        h-96
        w-full;
    }

    img {
      @apply
        mt-4
        w-full;
    }
  }
}
</style>