<template>
  <div class="filter-container">
    <strong>{{ filterLabel }}: </strong>
    <select
      class="filter"
      v-model="selectedCategory"
      @change="handleFilterChange"
      :disabled="!transitioned"
    > 
      <option
        v-for="category in categories"
        :key="category"
      >
        {{ category }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    filterLabel: {
      type: String,
      default: "Select category"
    },
    categories: {
      type: Array,
      required: true
    },
    transitioned: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedCategory: this.categories[0]
    }
  },
  methods: {
    handleFilterChange() {
      this.$emit('category-filter-changed', this.selectedCategory);
    }
  }
}
</script>

<style lang="postcss" scoped>
.filter-container {
  @apply 
    mt-4
    text-sm
    text-right
    text-gray-700;

  .filter {
    @apply
      border
      rounded
      border-gray-700
      text-gray-700
      px-2
      py-1;
  }
}
</style>