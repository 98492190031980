<template>
  <app-section-container
    sectionId="home-as-a-hub"
    sectionTitle="Home as a Hub"
    trend="Homes became more of a hub during the pandemic – with 64% of adults reporting sheltering at home and nearly half (47%) working from home."
  >
    <!-- Section content -->
    <div class="section-content">
      <p>
        Many people are spending more time at home, working remotely, shopping and socializing online. Access to and use of reliable technology is more important than ever, as homes continue to be the “mission control center” for Americans. Seventy-six percent of adults age 50-plus place high importance on having access to reliable and affordable in-home high-speed Internet. Adults age 50-plus place higher importance on this access compared to adults ages 18-49 (76% vs 70%).
      </p>
      <p>
        The pandemic has continued to have an impact on Americans’ feelings about their families and their communities. More older adults (58% of those ages 50-plus) report being extremely or very concerned about the impact of the COVID-19 pandemic on themselves and their families, compared with 42% of adults ages 18-49.
      </p>
    </div>

    <!-- Chart -->
    <app-chart-container
      title="Percent extremely or very concerned about the impact of COVID-19 on themselves or their families"
      subtitle="Adults ages 18-49 versus adults 50+"
      maxWidthClass="max-w-4xl"
    >
      <template v-slot="containerProps">
        <app-column-chart
          :data="chart1_1"
          :width="containerProps.width"
          :scrolledTo="containerProps.scrolledTo"
          :ariaLabel="containerProps.ariaLabel"
          :numberFormat="'Percent'"
          :showYAxis="false"
          :specifiedYMax="1"
          :columnColors="['#5C0033','#EC1300']"
          :multiColors="true"
        ></app-column-chart>
      </template>
    </app-chart-container>

    <!-- Section content -->
    <div class="section-content">
      <p>
        One in four adults age 50-plus report feeling less connected to their community since the COVID-19 outbreak. Younger adults are even more likely to feel less connected, isolated, and lonely.
      </p>
    </div>

    <!-- Chart -->
    <app-chart-container
      title="Change in feeling of connectedness to their community"
      subtitle="Since the COVID-19 outbreak"
      maxWidthClass="max-w-4xl"
    >
      <template v-slot="containerProps">
        <app-stacked-bar-chart
          :data="chart1_2"
          :width="containerProps.width"
          :scrolledTo="containerProps.scrolledTo"
          :ariaLabel="containerProps.ariaLabel"
          :marginLeft="85"
          :marginBottom="20"
          :specifiedXMax="1"
          :scaleTo100="true"
          :numberFormat="'Percent'"
          :showXAxis="false"
          :colors="['#EC1300', '#FFC19F', '#5C0033']"
          :textColors="['#FFF', '#000', '#FFF']"
          :tooltipTextColors="['#EC1300', '#ff9b64', '#5C0033']"
        ></app-stacked-bar-chart>
      </template>
    </app-chart-container>

    <!-- Section content -->
    <!-- <div class="section-content">
      <p>
        Seventy-three percent of adults place high importance on having access to reliable and affordable in-home high-speed Internet. Adults age 50-plus place higher importance on this access compared to adults ages 18-49 (76% vs 70%).
      </p>
    </div> -->

    <!-- EXAMPLES -->
    <!-- EXAMPLES -->
    <!-- EXAMPLES -->
    <h2 class="examples-title">
      AARP In the Community
    </h2>
    <div class="examples-container">
      <app-example         
        v-for="example in examples"
        :key="example.community"
        :exampleData="example"
      >
      </app-example>
    </div>
  </app-section-container>
</template>

<script>
import AppSectionContainer from '@/components/SectionContainer.vue';
import AppChartContainer from '@/components/charts/ChartContainer.vue';
import AppColumnChart from '@/components/charts/ColumnChart.vue';
import AppStackedBarChart from '@/components/charts/StackedBarChart.vue';
import AppExample from '@/components/reusables/Example.vue';

export default {
  name: 'Section 1',
  components: {
    AppSectionContainer,
    AppChartContainer,
    AppColumnChart,
    AppStackedBarChart,
    AppExample
  },
  data() {
    return {
      examples: [
        {
          // type: "Community Aging in Place Grant",
          community: "AARP Community Challenge Grants",
          summary: "The <a href='https://www.aarp.org/livable-communities/community-challenge/' target='_blank' rel='noreferrer noopener'>AARP Community Challenge</a> grant program is part of the nationwide <a href='https://www.aarp.org/livable-communities/about/' target='_blank' rel='noreferrer noopener'>AARP Livable Communities</a> initiative that helps communities become great places to live for residents of all ages. The program is intended to help communities make immediate improvements and jump-start long-term progress in support of residents of all ages. Since the program's debut in 2017, AARP has awarded $9.3 million through 800 grants.",
          // video: null,
          image: require('../../assets/images/community-challenge.webp'),
          alt: "AARP Community Challenge Grant"
        },
        {
          type: "Community Challenge Grantee",
          community: "Bynum, NC",
          summary: "Before the pandemic, Chatham County had a flourishing community center in the form of an old convenience store. Through funding as an <a href='https://www.aarp.org/livable-communities/community-challenge/' target='_blank' rel='noreferrer noopener'>AARP Community Challenge</a> grantee, the county was able to livestream the center’s monthly storytelling workshops and other events, allowing them to expand their reach and engagement with older adults, former residents, military families and others unable to gather in person due to distances or the COVID-19 pandemic.",
          video: "6261398165001",
        },
        // {
        //   // type: "Community Challenge Grant",
        //   community: "Jersey City, NJ",
        //   summary: "AARP Livable Communities awarded the Jersey City Housing Authority a grant that will enable them to provide free WiFi, smart home devices, a computer lab and digital literacy programming for older adults living in public housing developments. This will help reduce social isolation, bridge language barriers and create sustainable, on-site digital programming.",
        //   // video: "NEED LINK NEED LINK NEED LINK",
        // },
      ]
    }
  },
  computed: {
    chart1_1() {
      return this.selectChartData("1_1");
    },
    chart1_2() {
      return this.selectChartData("1_2");
    },
    chart1_3() {
      return this.selectChartData("1_3");
    }
  },
  methods: {
    selectChartData: function(chart) {
      return this.$store.getters.data.filter(d => d.Chart === chart);
    }
  }
}
</script>

<style lang="postcss" scoped>

</style>