<template>
  <div 
    class="chart-container"
    :class="maxWidthClass ? maxWidthClass : null"
  >
    <div 
      class="chart-header"
      v-if="title"
    >
      <p class="chart-title">{{ title }}</p>
      <p class="chart-subtitle" v-if="subtitle">{{ subtitle }}</p>
    </div>
    <div class="chart">
      <slot 
        v-if="width !== null"
        :width="width"
        :scrolledTo="scrolledTo"
        :ariaLabel="ariaLabel"
      ></slot>
    </div>
    <div class="chart-footer"
      v-if="source || notes"
    >
      <p class="chart-source" v-if="source"><strong>Source:</strong> <span v-html="source"></span></p>
      <p class="chart-notes" v-if="notes"><strong>Note(s): </strong> <span v-html="notes"></span></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Chart Container",
  props: {
    title: {
      type: String,
      required: false
    },
    subtitle: {
      type: String,
      required: false
    }, 
    source: {
      type: String,
      required: false
    },
    notes: {
      type: String,
      required: false
    },
    bottomTrigger: {
      type: String,
      required: false,
      default: "-50%"
    },
    maxWidthClass: {
      type: String,
      required: false,
      default: null,
    }
  },
  data() {
    return {
      width: null,
      scrolledTo: false
    }
  },
  computed: {
    ariaLabel() {
      if (this.subtitle) {
        return `${this.title} ${this.subtitle}`;
      } else {
        return `${this.title}`
      }
    }
  },
  methods: {
    setWidth() {
      const elRect = this.$el.getBoundingClientRect(),
            elWidth = elRect.width;

      const computedStyle = window.getComputedStyle(this.$el, null),
            paddingLeft = parseInt(computedStyle.getPropertyValue('padding-left')),
            paddingRight = parseInt(computedStyle.getPropertyValue('padding-right'));

      this.width = elWidth - paddingLeft - paddingRight;
    },
    startIntersectionObserver() {
      const observerOptions = {
        rootMargin: `0px 0px ${this.bottomTrigger} 0px`
      };

      const observer = new IntersectionObserver(el => {
        if (el[0].isIntersecting && !this.scrolledTo) {
          this.scrolledTo = true;
        }
      }, observerOptions);

      observer.observe(this.$el);
    }
  },
  mounted() {
    // Set width and check if scrolled to
    this.setWidth();
    this.startIntersectionObserver();

    // Add resize listener
    window.addEventListener("resize", this.setWidth);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.setWidth);
  }
}
</script>

<style lang="postcss" scoped>
.chart-container {
  @apply
    mx-auto
    px-4;
  /* @apply my-12; */

  .chart-header {
    /* @apply mb-8; */

    .chart-title {
      @apply 
        text-lg
        font-bold;
    }

    .chart-subtitle {
      @apply 
        text-sm
        italic;
    }
  }

  .chart-footer {
    @apply
      text-xs
      italic;

    :deep() {
      a {
        @apply
          border-red-700
          border-b
          border-dotted;

        transition: border-color 0.5s;

        &:hover {
          border-color: rgba(0,0,0,0);
        }
      }
    }
  }
}
</style>