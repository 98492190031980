// Import specific modules of d3 to minimize file size

import {
  // Selections
  select, selectAll,

  // Scales
  scaleLinear, scaleBand, scalePoint, scaleOrdinal, scaleSequential,
  
  // color schemes
  interpolateRgb,

  // Axes
  axisTop, axisBottom, axisLeft, 

  // Plotting stuff
  line, pie, arc, interpolate, geoPath, geoAlbersUsa, geoMercator,

  // Data manipulation
  group, min, max,

  // Interpolation
  interpolateNumber,

  // easing
  easeExpOut, easeQuadOut,

  // Miscellaneous
  format,
} from 'd3';

export default {
  select, selectAll,
  scaleLinear, scaleBand, scalePoint, scaleOrdinal, scaleSequential,
  interpolateRgb,
  axisTop, axisBottom, axisLeft, 
  line, pie, arc, interpolate, geoPath, geoAlbersUsa, geoMercator,
  group, min, max,
  interpolateNumber,
  easeExpOut, easeQuadOut,
  format, 
};