<template>
  <div class="slideshow">
    <!-- <transition name="fade"> -->
      <picture
        v-for="(picture, i) in pictures"
        :key="i"
      >
        <source
          v-for="(source, i) in picture.sources"
          :key="`${source}-${i}`"
          :srcset="source.src"
          :media="source.media"
        >
        <img 
          :src="picture.default"  
          :alt="picture.alt"
          :style="{ 'opacity': i === imageNumber ? 1 : 0 }"
        >
      </picture>
      <!-- <img
        v-for="(image, i) in images"
        :key="i"
        :src="image.url"
        :alt="image.alt"
        :style="{ 'opacity': i === imageNumber ? 1 : 0 }"
      > -->
    <!-- </transition> -->
    <!-- <img src="../../assets/images/cover1.webp">
    <img src="../../assets/images/cover2.webp">
    <img src="../../assets/images/cover3.webp">
    <img src="../../assets/images/cover4.webp">
    <img src="../../assets/images/cover5.webp">
    <img src="../../assets/images/cover6.webp"> -->
  </div>
</template>

<script>
export default {
  name: 'Cover Slideshow',
  data() {
    return {
      imageNumber: 0,
      pictures: [
        {
          sources: [
            {
              src: require('@/assets/images/cover1-640.webp'),
              media: '(max-width: 640px)',
            },
            {
              src: require('@/assets/images/cover1-768.webp'),
              media: '(max-width: 768px)',
            },
            {
              src: require('@/assets/images/cover1-1024.webp'),
              media: '(max-width: 1024px)',
            },
            {
              src: require('@/assets/images/cover1-1280.webp'),
              media: '(max-width: 1280px)',
            },
            {
              src: require('@/assets/images/cover1-1536.webp'),
              media: '(max-width: 1536px)',
            },
          ],
          default: require('@/assets/images/cover1.webp'),
          alt: "Picture of a neighborhood"
        },
        {
          sources: [
            {
              src: require('@/assets/images/cover2-640.webp'),
              media: '(max-width: 640px)',
            },
            {
              src: require('@/assets/images/cover2-768.webp'),
              media: '(max-width: 768px)',
            },
            {
              src: require('@/assets/images/cover2-1024.webp'),
              media: '(max-width: 1024px)',
            },
            {
              src: require('@/assets/images/cover2-1280.webp'),
              media: '(max-width: 1280px)',
            },
            {
              src: require('@/assets/images/cover2-1536.webp'),
              media: '(max-width: 1536px)',
            },
          ],
          default: require('@/assets/images/cover2.webp'),
          alt: "Picture of a neighborhood"
        },
        {
          sources: [
            {
              src: require('@/assets/images/cover3-640.webp'),
              media: '(max-width: 640px)',
            },
            {
              src: require('@/assets/images/cover3-768.webp'),
              media: '(max-width: 768px)',
            },
            {
              src: require('@/assets/images/cover3-1024.webp'),
              media: '(max-width: 1024px)',
            },
            {
              src: require('@/assets/images/cover3-1280.webp'),
              media: '(max-width: 1280px)',
            },
            {
              src: require('@/assets/images/cover3-1536.webp'),
              media: '(max-width: 1536px)',
            },
          ],
          default: require('@/assets/images/cover3.webp'),
          alt: "Picture of a neighborhood"
        },
        {
          sources: [
            {
              src: require('@/assets/images/cover4-640.webp'),
              media: '(max-width: 640px)',
            },
            {
              src: require('@/assets/images/cover4-768.webp'),
              media: '(max-width: 768px)',
            },
            {
              src: require('@/assets/images/cover4-1024.webp'),
              media: '(max-width: 1024px)',
            },
            {
              src: require('@/assets/images/cover4-1280.webp'),
              media: '(max-width: 1280px)',
            },
            {
              src: require('@/assets/images/cover4-1536.webp'),
              media: '(max-width: 1536px)',
            },
          ],
          default: require('@/assets/images/cover4.webp'),
          alt: "Picture of a neighborhood"
        },
        {
          sources: [
            {
              src: require('@/assets/images/cover5-640.webp'),
              media: '(max-width: 640px)',
            },
            {
              src: require('@/assets/images/cover5-768.webp'),
              media: '(max-width: 768px)',
            },
            {
              src: require('@/assets/images/cover5-1024.webp'),
              media: '(max-width: 1024px)',
            },
            {
              src: require('@/assets/images/cover5-1280.webp'),
              media: '(max-width: 1280px)',
            },
            {
              src: require('@/assets/images/cover5-1536.webp'),
              media: '(max-width: 1536px)',
            },
          ],
          default: require('@/assets/images/cover5.webp'),
          alt: "Picture of a neighborhood"
        },
        {
        sources: [
          {
            src: require('@/assets/images/cover6-640.webp'),
            media: '(max-width: 640px)',
          },
          {
            src: require('@/assets/images/cover6-768.webp'),
            media: '(max-width: 768px)',
          },
          {
            src: require('@/assets/images/cover6-1024.webp'),
            media: '(max-width: 1024px)',
          },
          {
            src: require('@/assets/images/cover6-1280.webp'),
            media: '(max-width: 1280px)',
          },
          {
            src: require('@/assets/images/cover6-1536.webp'),
            media: '(max-width: 1536px)',
          },
        ],
        default: require('@/assets/images/cover6.webp'),
        alt: "Picture of a neighborhood"
      },
      ]
      // images: [
      //   {
      //     url: require('@/assets/images/cover1.webp'),
      //     alt: 'Picture of a neighborhood',
      //   },
      //   {
      //     url: require('@/assets/images/cover2.webp'),
      //     alt: 'Picture of a neighborhood',
      //   },
      //   {
      //     url: require('@/assets/images/cover3.webp'),
      //     alt: 'Picture of a neighborhood',
      //   },
      //   {
      //     url: require('@/assets/images/cover4.webp'),
      //     alt: 'Picture of a neighborhood',
      //   },
      //   {
      //     url: require('@/assets/images/cover5.webp'),
      //     alt: 'Picture of a neighborhood',
      //   },
      //   {
      //     url: require('@/assets/images/cover6.webp'),
      //     alt: 'Picture of a neighborhood',
      //   },
      // ]
    }
  },
  methods: {
    setImageInterval() {
      const imageCount = this.pictures.length;
      setInterval(() => {
        if (this.imageNumber < imageCount - 1) {
          this.imageNumber++;
        } else {
          this.imageNumber = 0;
        }
      }, 5000);
    }
  },
  mounted() {
    // Set interval
    this.setImageInterval();
  }
}
</script>

<style lang="postcss" scoped>
.slideshow {
  @apply
    relative
    h-full
    w-full;

  img {
    @apply
      /* opacity-0 */
      absolute
      object-cover
      /* object-top */
      w-full
      h-full
      rounded
      top-0
      left-0;

    transition: opacity 1s linear;
  }
}
</style>