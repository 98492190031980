<template>
  <div class="cover">  
    <div class="container">
      <!-- Left (image) -->
      <div class="slideshow-container">
        <!-- Slideshow -->
        <app-cover-slideshow></app-cover-slideshow>
      </div>

      <!-- Cover content -->
      <div class="cover-content-container">
        <div class="cover-content">
          <div>
            <h1>
              Where We Live,<br>Where We Age 
            </h1>
            <h2>
              Trends in Home and Community Preferences
            </h2>
          </div>

          <!-- Logo -->
          <div>
            <p>
              <img src="../../assets/images/AARP_Logo_2020_Red.png" alt="AARP logo">
            </p>

            <!-- Scroll -->
            <div 
              class="scroll-down"
              @click="$scrollToAnchor('#intro', $event)"  
            >           
              Click or scroll down to continue<br>▼  
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import AppCoverSlideshow from '@/components/sections/CoverSlideshow.vue';

export default {
  name: "Cover",
  components: {
    AppCoverSlideshow,
  },
}
</script>

<style lang="postcss" scoped>
.cover {
  @apply
    w-full
    h-full
    pt-16
    pb-4;

  .container {
    @apply
      relative
      flex
      flex-col
      h-full;

    @screen xl {
      @apply
        static
        flex-row;
    }

    .slideshow-container {
      @apply
        flex-grow;
    }
    
    .cover-content-container {
      @apply
        absolute
        left-0
        bottom-0
        /* h-full */
        w-full
        flex
        flex-row
        justify-around;

      padding: inherit;

      @screen xl {
        @apply
          flex-col
          justify-end
          static
          w-1/3
          p-0;
      }

      .cover-content {
        @apply
          mx-8
          mb-8         
          p-8
          max-w-lg
          border
          border-gray-300
          rounded
          shadow
          text-center;

        background-color: rgba(255,255,255,0.95);

        @screen xl {
          @apply
            mx-0
            border-none
            shadow-none
            mb-0;
        }

        h1 {
          @apply
            font-sans
            font-bold
            text-3xl
            pb-4
            mb-4
            border-b
            border-aarp-black;

          font-family: 'Raleway', sans-serif;
        }

        h2 {
          @apply
            font-sans
            /* font-bold */
            text-xl
            mb-4;
        }

        p {
          @apply
            my-4;
            /* text-sm; */

          &:first-child {
            @apply
              mt-0;
          }

          &:last-child {
            @apply
              mb-0;
          }
        }

        img {
          @apply
            mx-auto;
        }

        .scroll-down {
          @apply
            inline-block
            mx-auto
            mt-4;
          
          animation: bounce 2s ease infinite;

          @keyframes bounce {
            0%   { transform: translateY(0); }
            50%  { transform: translateY(0.5rem); }
            100% { transform: translateY(0); }
          }

          &:hover {
            @apply
              cursor-pointer;
          }
        }

        /* Short screens */
        @media screen and (max-height: 480px) {
          @apply
            mb-4
            p-4
            max-w-none
            flex;

          div {
            @apply  
              mx-8
              flex
              flex-col
              justify-around;
          }

          h1 {
            @apply
              text-lg;
          }

          h2 {
            @apply  
              mb-0;
          }

          .scroll-down {
            @apply 
              mt-0;
          }
        }
      }
    }
  }

}


</style>