<template>
  <div>
    <!-- Legend -->
    <div class="legend"></div>

    <!-- Chart -->
    <svg
      :width="width"
      :height="height"
    >
    </svg>

    <!-- Tooltip -->
    <div class="tooltip"></div>
  </div>
</template>

<script>

export default {
  name: 'Dot Plot Chart',
  inject: ['$d3'],
  props: {
    data: {
      type: Array,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      default: 400
    },
    scrolledTo: {
      type: Boolean,
      required: true
    },
    marginTop: {
      type: Number,
      default: 20,
    },
    marginLeft: {
      type: Number,
      default: 40,
    },
    marginBottom: {
      type: Number,
      default: 40
    },
    marginRight: {
      type: Number,
      default: 50,
    },
    transitionTime: {
      type: Number,
      default: 1000,
    },
    showXAxis: {
      type: Boolean,
      default: true
    },
    specifiedXMax: {
      type: Number,
      default: null
    },
    sortDirection: {
      type: String,
      default: null,
    },
    labelUnit: {
      type: String,
      default: null
    },
    dotRadius: {
      type: Number,
      default: 5
    },
    colors: {
      type: Array,
      default: () => ["#9e2121", "#104270", "#d4a02a", "#009b48", "#1c90a3", "#3d143b"],
    },
    legendTextColors: {
      type: Array,
      default: () => ["#fff", "#fff", "#fff", "#fff", "#fff", "#fff"],
    },
    lineColor: {
      type: String,
      default: "black"
    },
    numberFormat: {
      type: String,
      default: "Number"
    },
    ariaLabel: {
      type: String,
      required: true,
    }
  },
  computed: {
    svg() {
      const svg = this.$d3.select(this.$el)
        .select("svg")
        .attr("role", "list")
        .attr("aria-label", this.ariaLabel)
          .append("g")
          .attr("transform", `translate(0,0)`);
          // .attr("transform", `translate(${this.margin.left}, ${this.margin.top})`);

      return svg;
    },
    tooltip() {
      const tooltip = this.$d3.select(this.$el)
        .select(".tooltip");

      return tooltip;
    },
    legend() {
      const legend = this.$d3.select(this.$el)
        .select(".legend");

      return legend;
    },
    categories() {
      const categories = Array.from(new Set(this.data.map(row => row.Category)));

      return categories;
    },
    yAxisCategories() {
      // depends on whether subCategories exists or not
      const yAxisCategories = Array.from(new Set(this.data.map(row => row.SubCategory)));

      return yAxisCategories;
    },
    dimensions() {
      // Add it as text for categories on the svg to calculate BBox widths
      // const longestCategoryBoxes = this.svg.selectAll(".longest-category-box")
      //   .data(this.yAxisCategories)
      //   .enter().append("g")
      //     .attr("class", "longest-category-box")
      //     .append("text")
      //       .text(d => d);

      // let longestCategoryBoxWidth = 0;
      // longestCategoryBoxes.each(function() {
      //   const boxWidth = this.getBBox().width;

      //   if (boxWidth > longestCategoryBoxWidth) {
      //     longestCategoryBoxWidth = boxWidth + 10;
      //   }
      // });

      // // Remove the box
      // this.svg.selectAll(".longest-category-box")
      //   .remove();

      const margin = {
        top: this.marginTop,
        right: this.marginRight,
        left: this.marginLeft,
        bottom: this.marginBottom
      };

      return {
        margin: margin,
        chart: {
          width: this.width - margin.right - margin.left,
          height: this.height - margin.top - margin.bottom
        }
      }
    },
    xAxis() {
      const xMax = this.specifiedXMax !== null ? this.specifiedXMax : this.$d3.max(this.data.map(row => row.Value));

      const xAxis = this.$d3.scaleLinear()
        .range([0, this.dimensions.chart.width])
        .domain([0, xMax]);

      return xAxis;
    },
    xAxisCall() {
      const xAxisCall = this.$d3.axisBottom(this.xAxis)
        .tickSizeOuter(0)
        .tickFormat(this.numberFormatter);

      return xAxisCall;
    },
    yAxis() {
      const yAxis = this.$d3.scaleBand()
        .rangeRound([0, this.dimensions.chart.height])
        .domain(this.yAxisCategories)
        .padding(0.5);

      return yAxis;
    },
    yAxisCall() {
      const yAxisCall = this.$d3.axisLeft(this.yAxis)
        .tickSizeOuter(0);
        // .tickSize(0);

      return yAxisCall;
    },
    numberFormatter() {
      let numberFormatter;
      if (this.numberFormat === "Number") {
        numberFormatter = this.$d3.format(",.0f");
      } else if (this.numberFormat === "Percent") {
        numberFormatter = this.$d3.format(".0%");
      }

      return numberFormatter;
    },
    colorScale() {
      const colorScale = this.$d3.scaleOrdinal()
        .domain(this.categories)
        .range(this.colors);

      return colorScale;
    },
    legendTextColorScale() {
      const legendTextColorScale = this.$d3.scaleOrdinal()
        .domain(this.categories)
        .range(this.legendTextColors);

      return legendTextColorScale;
    },
  },
  methods: {
    drawChart() { 
      // Add stuff into legend
      this.legend.selectAll(".legend-entry")
        .data(this.categories)
        .enter().append("div")
          .attr("class", "legend-entry")
          .style("background-color", d => this.colorScale(d))
          .style("color", d => this.legendTextColorScale(d))
          .text(d => d);

      // Set svg transform
      this.svg
        .attr("transform", `translate(${this.dimensions.margin.left}, ${this.dimensions.margin.top})`);

      // Draw lines
      this.svg.selectAll(".line")
        .data(this.data)
        .enter().append("line")
          .attr("class", "line")
          .attr("x1", this.xAxis(0))
          .attr("x2", this.dimensions.chart.width)
          .attr("y1", d => this.yAxis(d.SubCategory) + this.yAxis.bandwidth()/2)
          .attr("y2", d => this.yAxis(d.SubCategory) + this.yAxis.bandwidth()/2)
          .style("stroke", this.lineColor)
          .attr("aria-hidden", "true");

      // Draw dots
      this.svg.selectAll(".dot")
        .data(this.data)
        .enter().append("circle")
          .attr("class", "dot")
          .attr("cx", this.xAxis(0))
          .attr("cy", d => this.yAxis(d.SubCategory) + this.yAxis.bandwidth()/2)
          .attr("r", 0)
          .attr("role", "listitem")
          .attr("aria-label", d => `${d.SubCategory}, ${d.Category}: ${this.numberFormatter(d.Value)}`)
          .style("fill", d => this.colorScale(d.Category))
          .on("mouseover", (event, d) => {
            this.tooltip
              .classed("active", true)
              .style("border-color", this.colorScale(d.Category))
              .style("color", this.colorScale(d.Category))
              .html(`
                <p><strong>${d.SubCategory}</strong></p>
                <p><em>${d.Category}: ${this.numberFormatter(d.Value)}</em></p>
              `);
          })
          .on("mousemove", (event) => {
            // Set position of tooltip
            const bb = this.tooltip.node().getBoundingClientRect();

            this.tooltip
              .style("left", `${event.clientX - bb.width / 2}px`)
              .style("top", `${event.clientY - bb.height - 5}px`);
          })
          .on("mouseout", () => {
            // hide tooltip
            this.tooltip
              .classed("active", false)
              .html();
          });

      // Add axes
      this.svg.append("g")
        .attr("class", "y axis")
        .attr("aria-label", "Y-axis")
        .attr("aria-hidden", "true")
        .call(this.yAxisCall);

      // Draw x-axis if required
      if (this.showXAxis) {
        this.svg.append("g")
          .attr("class", "x axis")
          .attr("aria-label", "X-axis")
          .attr("transform", `translate(0, ${this.dimensions.chart.height})`)
          .attr("aria-hidden", "true")
          .call(this.xAxisCall);
      }

      // Add data labels
      this.svg.selectAll(".label")
        .data(this.data)
        .enter().append("text")
          .attr("class", "label")
          .attr("x", d => this.xAxis(d.Value))
          // .attr("dx", 2 * this.dotRadius)
          .attr("y", d => this.yAxis(d.SubCategory) + this.yAxis.bandwidth()/2)
          .attr("dy", -2 * this.dotRadius)
          .attr("aria-hidden", "true")
          .style("opacity", 0)
          .style("fill", d => this.colorScale(d.Category))
          .text(d => {
            if (this.labelUnit !== null) {
              return `${this.numberFormatter(d.Value)} ${this.labelUnit}`;
            } else {
              return this.numberFormatter(d.Value);
            }
          })

      // Animate if needed
      if (this.scrolledTo) {
        this.animate();
      }
    },
    animate() {
      // lines and dots
      // this.svg.selectAll(".line")
      //   .transition()
      //     .duration(this.transitionTime)
      //     .attr("x2", d => this.xAxis(d.Value))

      // Dots
      this.svg.selectAll(".dot")
        .transition()
          .duration(this.transitionTime)
          .attr("r", this.dotRadius)
          .attr("cx", d => this.xAxis(d.Value))
          .on("end", () => {
            // Data labels                  
            this.svg.selectAll(".label")
              .transition()
                .duration(this.transitionTime)
                .style("opacity", 1);
          });
    },
    resize() {      
      if (this.showXAxis) {
        // Re-call x-axis
        this.svg.select(".x.axis")
          .call(this.xAxisCall);
      }
  
      // Adjust dots
      this.svg.selectAll(".dot")
        .attr("cx", d => this.xAxis(d.Value));

      // Adjust data labels
      this.svg.selectAll(".label")
        .attr("x", d => this.xAxis(d.Value));

      this.svg.selectAll(".line")
        .attr("x2", this.dimensions.chart.width);
    },
  },
  mounted() {
    // Draw on mount
    this.drawChart();

    // Resize listener
    window.addEventListener("resize", this.resize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.resize);
  },
  watch: {
    scrolledTo: function(newValue) {
      // If scrolled to then...
      if (newValue) {
        // Trigger animations if not transitioned
        if (!this.transitioned) {
          this.animate();
        }
      }
    } 
  }
}
</script>

<style lang="postcss" scoped>
.legend {
  @apply 
    flex
    flex-row
    text-sm
    italic
    justify-center
    mt-8;

  font-family: 'ff-dagny-web-pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  :deep() {
    .legend-entry {
      @apply
        p-1        
        px-2
        mx-1
        text-center;
    }
  }
}

svg {
  :deep() {
    @apply font-sans;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    text {
      @apply text-black;
    }

    .line {
      stroke-width: 1;
      shape-rendering: crispEdges;
    }

    .label {
      text-anchor: middle;
    }

    .axis {
      @apply font-sans;

      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      &.y {
        .tick {
          line {
            @apply invisible;
          }
          
          text {
            @apply text-sm;
          }
        }
      }
    }

  .longest-category-box {
      @apply text-sm;
    }
  }
}

.tooltip {
  @apply
    fixed
    pointer-events-none
    invisible
    max-w-sm
    bg-white
    border
    border-2
    border-solid
    rounded
    p-2
    text-left
    text-sm
    shadow
    text-gray-800;

  &.active {
    @apply
      visible
      pointer-events-auto;
  }
}
</style>