<template>
  <section :id="sectionId">
    <div class="container">

      <!-- Section header -->
      <div class="section-header">
        <h1 v-if="sectionTitle">
          {{ sectionTitle }}
        </h1>

        <!-- Trend -->
        <div 
          class="trend"
          v-if="trend"
        >
          <p>
            <strong>TREND:</strong> <span v-html="trend"></span>
          </p>
        </div>
        <!-- <div 
          class="trend-prediction"
          v-if="trend && prediction"
        >
          <div class="trend">
            <p>
              <strong>TREND:</strong> <span v-html="trend"></span>
            </p>
          </div>
          <div class="prediction">
            <p>
              <strong>PREDICTION:</strong> <span v-html="prediction"></span>
            </p>
          </div>
        </div> -->
      </div>

      <!-- Section content -->
      <slot></slot>
    </div>
  </section>
</template>

<script>
  // import AppChartContainer from '@/components/charts/ChartContainer.vue';
  // import AppLollipopChart from '@/components/charts/LollipopChart.vue';
  // import AppSlopegraph from '@/components/charts/Slopegraph.vue';

  export default {
    name: 'Section Container',
    props: {
      sectionId: {
        type: String,
        required: true,
      },
      sectionTitle: {
        type: String,
        required: false,
      },
      trend: {
        type: String,
        required: false,
      },
      prediction: {
        type: String,
        required: false,
      }
    }
    // components: {
    //   AppChartContainer,
    //   AppLollipopChart,
    //   AppSlopegraph,
    // },
    // data() {
    //   return {
    //     points: [
    //       "While half (50%) of adults are concerned about the impact of COVID-19 on themselves and their families, older adults are especially concerned. Fifty-nine percent of 50-plus adults are extremely or very concerned compared with 41% of adults ages 18-49.",
    //       "Remote working, shopping online, and homeschooling are some ways Americans have adjusted to the pandemic. Sixty-five percent have sheltered at home and nearly half began working remotely from home (47%).",
    //       "Half or more have gone out less often by stocking up on supplies (57%) and shopping online for supplies (49%). Another three in ten have homeschooled their children or grandchildren (31%).",
    //       "The pandemic has encouraged more online activities and virtual meet ups. Sixty-four percent of adults have used video chat such as Zoom, Skype, or Facetime to connect with family and friends who do not live with them. Fifty-one percent participated in virtual doctor visits and 34% have attended religious services online.",
    //       "Moreover, 73% of adults place high importance on having access to reliable and affordable in-home high-speed Internet.",
    //       "Nearly one in three adults report feeling less connected to their community since the COVID-19 pandemic began. Older Americans are more likely to say the pandemic has had no effect on their attachment to their community, but among those ages 18-24, 42% say they feel more detached from their community since the start of the pandemic.",
    //       "Younger adults are also more likely to report feelings of isolation or loneliness.  Fifty-six percent of adults ages 18-24 say they lack companionship, 63% say they often or sometimes feel left out, and another 63% feel isolated. In comparison, only 29% of adults 50-plus say they lack companionship, 27% feel isolated, or 23% feel left out."
    //     ]
    //   }
    // },
    // computed: {
    //   chart1_1() {
    //     return this.selectChartData("1_1");
    //   },
    //   chart1_2() {
    //     return this.selectChartData("1_2");
    //   }
    // },
    // methods: {
    //   selectChartData: function (chart) {
    //     return this.$store.getters.data.filter(d => d.Chart === chart);
    //   }
    // }
  }
</script>

<style lang="postcss" scoped>
section {
  @apply 
    mt-12
    mb-24;

  .container {
    .section-header {
      @apply 
        mx-auto 
        max-w-4xl 
        text-center;

      h1 {
        @apply
          mb-12
          pb-4
          font-bold 
          text-4xl
          border-b 
          border-aarp-black
          break-words;

        font-family: 'Raleway', sans-serif;

        @screen md {
          @apply
            text-5xl;
        }
      }

      .trend {
        @apply 
          text-2xl
          text-white
          mx-2 
          p-4 
          border 
          rounded
          shadow
          bg-aarp-red-dark;
      }
    }

    :deep() {
      .section-content {
        @apply 
          mx-auto 
          max-w-4xl
          p-8;

        h2 {
          @apply
            font-bold
            mb-4;
        }

        p {
          @apply 
            mb-4;
        }

        ul {
          @apply 
            list-outside 
            list-disc 
            pl-4
            ml-4;
        }

        li {
          @apply 
           mb-4;

          /* &:last-child {
            @apply 
              mb-0;
          } */
        }

        a {
          @apply
            border-b
            border-aarp-red-light;

          transition: border-color 300ms;

          &:hover {
            @apply
              border-transparent;
          }
        }

        iframe {
          &.brightcove-video {
            @apply
              w-full
              h-96;
          }
        }

        /* Call out styling */
        .call-out {
          @apply
            m-0
            mb-4;

          @screen lg {
            @apply              
              ml-4
              mb-4
              float-right
              w-1/2;
          }
          
          &.full-width {
            @apply
              m-0
              w-full
              float-none;
          }

          .call-out-content {
            @apply
              p-4
              border
              border-gray-200
              rounded
              shadow
              bg-gray-100;

            ul {
              @apply
                ml-0;
                
              li {
                @apply
                  mb-4;
              }
            }

            p {
              &:last-child,
              &:only-child {
                @apply mb-0;
              }
            }

            .source {
              @apply
                text-xs
                mb-0;

              a {
                @apply
                  border-b
                  border-black;

                transition: border-color 300ms;

                &:hover {
                  @apply
                    border-transparent;
                }
              }
            }
          }
        }
      }

      /* Title for examples */
      h2 {
        &.examples-title {
          @apply
            mx-auto
            mb-4
            pb-4
            border-b
            border-aarp-red-dark
            max-w-4xl
            text-2xl
            text-aarp-red-dark
            text-center
            font-bold;

          font-family: 'Raleway', sans-serif;
        }
      }

      .examples-container {
        @apply
          grid
          grid-cols-1
          justify-items-center
          place-content-center;

        @screen lg {
          @apply
            grid-cols-2;
        }
      }
    }
  }
}
</style>