<template>
  <div>
    <!-- Legend -->
    <div class="legend"></div>

    <!-- SVG -->
    <svg
      :width="width"
      :height="height"
    >
    </svg>

    <!-- Tooltip -->
    <div class="tooltip"></div>
  </div>
</template>

<script>
export default {
  name: 'Stacked Bar Chart',
  inject: ['$d3'],
  props: {
    data: {
      type: Array,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      default: 400
    },
    scrolledTo: {
      type: Boolean,
      required: true
    },
    marginTop: {
      type: Number,
      default: 20,
    },
    marginLeft: {
      type: Number,
      default: 40,
    },
    marginBottom: {
      type: Number,
      default: 40
    },
    marginRight: {
      type: Number,
      default: 10
    },
    transitionTime: {
      type: Number,
      default: 1000,
    },
    showYAxis: {
      type: Boolean,
      default: true
    },
    showXAxis: {
      type: Boolean,
      default: true
    },
    specifiedXMax: {
      type: Number,
      default: null
    },
    scaleTo100: {
      type: Boolean,
      default: false
    },
    labelUnit: {
      type: String,
      default: null
    },
    numberFormat: {
      type: String,
      default: "Number"
    },
    specifiedSubCategoryOrder: {
      type: Array,
      default: null
    },
    colors: {
      type: Array,
      default: () => ["#efefef", "#e2e8f0", "#a0aec0", "#4a5568"]
    },
    textColors: {
      type: Array,
      default: () => ["#000000", "#000000", "#000000", "#ffffff"]
    },
    tooltipTextColors: {
      type: Array,
      default: () => ["#efefef", "#e2e8f0", "#a0aec0", "#4a5568"]
    },
    ariaLabel: {
      type: String,
      required: true
    }
  },
  computed: {
    svg() {
      const svg = this.$d3.select(this.$el)
        .select("svg")
        .attr("role", "list")
        .attr("aria-label", this.ariaLabel)
        .append("g")
          .attr("transform", `translate(0,0)`);

      return svg;
    },
    tooltip() {
      const tooltip = this.$d3.select(this.$el)
        .select(".tooltip");

      return tooltip;
    },
    yAxisCategories() {
      const yAxisCategories = Array.from(new Set(this.data.map(row => row.Category)));
      
      return yAxisCategories;
    },
    dimensions() {
      // Add it as text for categories on the svg to calculate BBox widths
      // const longestCategoryBoxes = this.svg.selectAll(".longest-category-box")
      //   .data(this.yAxisCategories)
      //   .enter().append("g")
      //     .attr("class", "longest-category-box")
      //     .append("text")
      //       .text(d => d);

      // let longestCategoryBoxWidth = 0;
      // longestCategoryBoxes.each(function() {
      //   const boxWidth = this.getBBox().width;

      //   if (boxWidth > longestCategoryBoxWidth) {
      //     longestCategoryBoxWidth = boxWidth + 10;
      //   }
      // });

      // // Remove the box
      // this.svg.selectAll(".longest-category-box")
      //   .remove();

      const margin = {
        top: this.marginTop,
        right: this.marginRight,
        left: this.marginLeft,
        bottom: this.marginBottom
      };

      // Set svg transform
      this.svg
        .attr("transform", `translate(${margin.left}, ${margin.top})`);

      return {
        margin: margin,
        chart: {
          width: this.width - margin.right - margin.left,
          height: this.height - margin.top - margin.bottom
        }
      }
    },
    legend() {
      const legend = this.$d3.select(this.$el)
        .select(".legend");

      return legend;
    },
    subCategories() {
      let subCategories;
      
      if (this.specifiedSubCategoryOrder !== null) {
        // This is using a specified order
        subCategories = this.specifiedSubCategoryOrder;
      } else {
        // This will be based on the default order from the data
        subCategories = Array.from(new Set(this.data.map(row => row.SubCategory)));
      }

      return subCategories;
    },
    colorScale() {
      const colorScale = this.$d3.scaleOrdinal()
        .domain(this.subCategories)
        .range(this.colors);

      return colorScale;
    },
    textColorScale() {
      const textColorScale = this.$d3.scaleOrdinal()
        .domain(this.subCategories)
        .range(this.textColors);

      return textColorScale;
    },
    tooltipTextColorScale() {
      const tooltipTextColorScale = this.$d3.scaleOrdinal()
        .domain(this.subCategories)
        .range(this.tooltipTextColors);

      return tooltipTextColorScale;
    },
    nestedData() {
      // Nest data by category
      const nestedData = this.$d3.group(this.data, d => d.Category),
            nestedDataArray = Array.from(nestedData, ([key, values]) => ({ key, values }));

      // Calculate beginning point for each
      nestedDataArray.forEach(key => {

        // If necessary, sort the values array
        if (this.specifiedSubCategoryOrder !== null) {
          key.values.sort((a, b) => {
            return this.specifiedSubCategoryOrder.indexOf(a.SubCategory) - this.specifiedSubCategoryOrder.indexOf(b.SubCategory);
          });
        }

        let runningTotal = 0;
        
        // Determine totals for each values array
        const valuesTotal = key.values.reduce((acc, val) => acc + val.Value, 0);

        key.values.forEach(val => {
          val.x0 = runningTotal;

          if (this.scaleTo100) {
            val.displayValue = val.Value/valuesTotal;
          } else {
            val.displayValue = val.Value;            
          } 
          
          runningTotal += val.displayValue;
        });
      });

      return nestedDataArray;
    },
    xAxis() {
      
      // If specified xMax, use specified; else, determine by going through the nested data
      let xMax;

      if (this.specifiedXMax !== null) {
        xMax = this.specifiedXMax;
      } else {
        let currentMax = 0;

        // Loop through nested categories and, using the last value, determine the total for that category
        this.nestedData.forEach(category => {
          const lastObs = category.values[category.values.length - 1],
                lastObsSum = lastObs.x0 + lastObs.Value;

          lastObsSum > currentMax ? currentMax = lastObsSum : null;
        });

        xMax = currentMax;
      }

      const xAxis = this.$d3.scaleLinear()
        .rangeRound([0, this.dimensions.chart.width])
        .domain([0, xMax]);

      return xAxis;
    },
    xAxisCall() {
      const xAxisCall = this.$d3.axisBottom(this.xAxis)
        .tickSizeOuter(0)
        .tickFormat(this.numberFormatter);

      return xAxisCall;
    },
    yAxis() {
      const yAxis = this.$d3.scaleBand()
        .rangeRound([0, this.dimensions.chart.height])
        .domain(Array.from(new Set(this.data.map(row => row.Category))))
        .padding(0.25);

      return yAxis;
    },
    yAxisCall() {
      const yAxisCall = this.$d3.axisLeft(this.yAxis)
        .tickSizeOuter(0);

      return yAxisCall;
    },
    numberFormatter() {
      let numberFormatter;
      if (this.numberFormat === "Number") {
        numberFormatter = this.$d3.format(",.0f");
      } else if (this.numberFormat === "Percent") {
        numberFormatter = this.$d3.format(".0%");
      }

      return numberFormatter;
    }
  },
  methods: {
    drawChart() {
      // Add stuff into legend
      this.legend.selectAll(".legend-entry")
        .data(this.subCategories)
        .enter().append("div")
          .attr("class", "legend-entry")
          .style("background-color", d => this.colorScale(d))
          .style("color", d => this.textColorScale(d))
          .text(d => d);

      // Draw bars
      const barGroups = this.svg.selectAll(".bar-group")
        .data(this.nestedData)
        .enter().append("g")
          .attr("class", "bar-group");

      barGroups.selectAll(".bar-segment")
        .data(d => d.values)
        .enter().append("rect")
          .attr("class", "bar-segment")          
          .attr("x", d => this.xAxis(d.x0))
          .attr("width", 0)
          .attr("y", d => this.yAxis(d.Category))
          .attr("height", this.yAxis.bandwidth())
          .attr("role", "listitem")
          .attr("aria-label", d => `${d.Category}, ${d.SubCategory}: ${this.numberFormatter(d.Value)}`)
          .style("fill", d => this.colorScale(d.SubCategory))
          .on("mouseover", (event, d) => {
            this.tooltip
              .style("visibility", "visible")
              .style("border-color", this.colorScale(d.SubCategory))
              .style("color", this.tooltipTextColorScale(d.SubCategory))
              .html(`
                <p><strong>${d.Category}</strong></p>
                <p><em>${d.SubCategory}: ${this.numberFormatter(d.Value)}</em></p>
              `);
          })
          .on("mousemove", (event) => {
            // Set position of tooltip
            const bb = this.tooltip.node().getBoundingClientRect();

            this.tooltip
              .style("left", `${event.clientX - bb.width / 2}px`)
              .style("top", `${event.clientY - bb.height - 5}px`);
          })
          .on("mouseout", () => {
            // hide tooltip
            this.tooltip
              .style("visibility", "hidden")
              .html();
          });

      // Add y-axis if required
      if(this.showYAxis) {
        this.svg.append("g")
          .attr("class", "y axis")
          .attr("aria-hidden", "true")
          .call(this.yAxisCall);
      }

      // Draw x-axis if required
      if (this.showXAxis) {
        this.svg.append("g")
          .attr("class", "x axis")
          .attr("transform", `translate(0, ${this.dimensions.chart.height})`)
          .attr("aria-hidden", "true")
          .call(this.xAxisCall);
      }

      // Add data labels
      barGroups.selectAll(".label")
        .data(d => d.values)
        .enter().append("text")
          .attr("class", "label")
          .attr("x", d => this.xAxis(d.x0) + this.xAxis(d.displayValue)/2)
          .attr("y", d => this.yAxis(d.Category) + this.yAxis.bandwidth()/2)
          .attr("dy", "0.3em")
          .attr("aria-hidden", "true")
          .style("opacity", 0)
          .style("fill", d => this.textColorScale(d.SubCategory))
          .text(d => {
              if (this.labelUnit !== null) {
                  return `${this.numberFormatter(d.Value)} ${this.labelUnit}`;
            } else {
                return this.numberFormatter(d.Value);
            }
          })

      // Animate if needed
      if (this.scrolledTo) {
        this.animate();
      }
    },
    animate() {
      // Bar groups
      const barGroups = this.svg.selectAll(".bar-group");

      // Bar segments
      barGroups.selectAll(".bar-segment")
        .transition()
          .duration(this.transitionTime)
          .delay((d, i) => this.transitionTime * i)
          .attr("width", d => this.xAxis(d.displayValue));

      // Data labels
      const xAxis = this.xAxis;

      barGroups.selectAll(".label")
        .transition()
          .duration(this.transitionTime)
          .delay((d, i) => (i + 1) * this.transitionTime) // should become visible after bars animate
          .style("opacity", function(d) {
          const barSegmentWidth = xAxis(d.displayValue),
                textBoxWidth = this.getBBox().width;

          return textBoxWidth + 5 >= barSegmentWidth ? 0 : 1;
        });
    },
    resize() {
      // Re-call x-axis
      if (this.showXAxis) {
        this.svg.select(".x.axis")
          .call(this.xAxisCall);
      }

      // Adjust bar positions and widths
      const barGroups = this.svg.selectAll(".bar-group");

      barGroups.selectAll(".bar-segment")
        .attr("x", d => this.xAxis(d.x0));

      // Adjust data label positions
      barGroups.selectAll(".label")
        .attr("x", d => this.xAxis(d.x0) + this.xAxis(d.displayValue)/2);
      
      // ONLY IF ALREADY ANIMATED
      if (this.scrolledTo) {
        barGroups.selectAll(".bar-segment, .label")
          .interrupt();

        // Transition width of segments if needed
        barGroups.selectAll(".bar-segment")
          .attr("width", d => this.xAxis(d.displayValue));

        // Determine appropriate opacity
        // Store the x-axis function here
        const xAxis = this.xAxis;

        barGroups.selectAll(".label")
          .style("opacity", function(d) {
            const barSegmentWidth = xAxis(d.displayValue),
                  textBoxWidth = this.getBBox().width;

            return textBoxWidth + 5 >= barSegmentWidth ? 0 : 1;
          });
      }
    }
  },
  mounted() {
    // Draw on mount
    this.drawChart();

    // Resize listener
    window.addEventListener("resize", this.resize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.resize);
  },
  watch: {
    scrolledTo(newValue) {
      // If scrolled to then...
      if (newValue) {
        // Trigger animations if not transitioned
        if (!this.transitioned) {
          this.animate();
        }
      }
    }
  }
}
</script>

<style lang="postcss" scoped>
.legend {
  @apply 
    flex
    flex-row
    text-sm
    italic
    justify-center
    mt-8;

  font-family: 'ff-dagny-web-pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  :deep() {
    .legend-entry {
      @apply
        p-1
        px-2
        mx-1
        text-center;
    }
  }
}

svg {
  :deep() {
    @apply font-sans;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    text {
      @apply text-black;
    }

    .bar-group {
      .bar-segment {
        fill: white;
        stroke: white;
        stroke-width: 1;
        shape-rendering: crispEdges;
      }

      .label {
        @apply
          pointer-events-none;

        /* fill: black; */
        text-anchor: middle;
      }
    }

    .axis {
      @apply font-sans;

      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      &.y {
        .tick {
          line {
            @apply invisible;
          }
          
          text {
            @apply text-sm;
          }
        }
      }
    }
    
    .longest-category-box {
      @apply text-sm;
    }
  }
}

.tooltip {
  @apply
    fixed
    pointer-events-none
    max-w-sm
    invisible
    
    /* General styling */
    bg-white
    border
    border-2
    border-solid
    rounded
    p-2
    text-left
    text-sm
    shadow
    text-gray-800;
}
</style>