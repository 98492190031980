<template>
  <!-- Loader -->
  <transition
    name="fade"
    mode="out-in"
  >
    <app-loader v-if="!dataLoaded"></app-loader>
  </transition>

  <!-- App -->
  <template v-if="dataLoaded">

    <!-- Header -->
    <app-header></app-header>

    <!-- Sections -->
    <app-cover></app-cover>
    <app-introduction></app-introduction>
    <app-section-1></app-section-1>
    <app-section-2></app-section-2>
    <app-section-3></app-section-3>
    <app-section-4></app-section-4>
    <app-section-5></app-section-5>
    <app-resources></app-resources>

    <!-- Footer -->
    <app-footer></app-footer>
  </template>
</template>

<script>
import AppLoader from '@/components/Loader.vue';
import AppHeader from '@/components/Header.vue';
import AppCover from '@/components/sections/Cover.vue';
import AppIntroduction from '@/components/sections/Introduction.vue';
import AppSection1 from '@/components/sections/Section1.vue';
import AppSection2 from '@/components/sections/Section2.vue';
import AppSection3 from '@/components/sections/Section3.vue';
import AppSection4 from '@/components/sections/Section4.vue';
import AppSection5 from '@/components/sections/Section5.vue';
import AppResources from '@/components/sections/Resources.vue';
import AppFooter from '@/components/sections/Footer.vue';

export default {
  name: 'App',
  components: {
    AppLoader,
    AppHeader,
    AppCover,
    AppIntroduction,
    AppSection1,
    AppSection2,
    AppSection3,
    AppSection4,
    AppSection5,
    AppResources,
    AppFooter
  },
  computed: {
    dataLoaded() {
      return this.$store.getters.dataLoaded;
    },
  },
  created() {
    // Load data for charts
    this.$store.dispatch('loadData');
  }
}
</script>

<style lang="postcss">
/* Header font */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Raleway:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap');

/* 100% height */
html,
body,
#app {
  @apply
    h-full;
}

#app {  
  @apply
    text-aarp-black
    font-sans;
    /* p-8; */

  .container {
    @apply 
      mx-auto
      px-4;

    /* .header {
      @apply
        flex
        flex-row
        items-start
        justify-between
        border-b-2
        border-aarp-black
        pb-4
        mb-4;

      h1 {
        @apply
          inline-block
          text-3xl
          font-bold;
      } 
    } */

    /* p {
      @apply
        mb-4;
    } */

    /* .color-square {
      @apply
        border;
    } */
  }
}

/* Transitions */
/* Fade */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
