<template>
  <app-section-container
    sectionId="community"
    sectionTitle="What Makes a Good Community?"
    trend="Two-thirds (67%) of all adults — and 79% of those 50-plus — want to stay in their current communities. Adults primarily value communities that foster good health, promote street safety, and provide good opportunities for community engagement and social interaction. "
  >
    <!-- Section content -->
    <div class="section-content">
      <p>
        Over four in ten (44%) want to live in a community where all ages are welcome. And a strong majority (71%) of adults believe it is extremely or very important for their community to treat residents equally regardless of race, gender, age, ethnic background, sexual orientation, or other differences.
      </p>

      <p>
        Nearly three-quarters of adults (73%) say it is important to have safe, well-lit parks that give residents places to gather and interact with friends and neighbors. A majority of adults think it is important to have access to:
      </p>  

      <ul>
        <li>Clean, safe water (82%)</li>
        <li>Healthy foods (75%)</li>
        <li>Quality health care (73%)</li>
        <li>Safe trails and paths to walk, run, or bike (62%)</li>
      </ul>

      <p>
        Driving is still the most common mode of transportation for Americans. Nine in 10 (87%) adults drive themselves to get around their community, and 90% of 65-plus adults drive themselves. 
      </p>

      <div class="icon-container">
        <h2>Most Important Community Features for Transportation</h2>
        <div class="icon-grid">
          <div
            class="icon"
            v-for="feature in features"
            :key="feature.name"
          >
            <img
              loading="lazy"
              :src="feature.icon"
              :alt="feature.alt"
            >
            <div class="caption">
              <div class="value">
                {{ feature.value }}
              </div>
              <div class="name">
                {{ feature.name }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <p>
        Regardless of age, many adults would consider leaving their current community to find a community that is safer, more affordable, has better housing, or better healthcare. 
      </p>
    </div>

    <!-- Chart -->
    <app-chart-container
      title="Percent who would consider leaving their current community to find one that..."
      maxWidthClass="max-w-4xl"
    >
      <template v-slot="containerProps">
        <app-lollipop-chart
          :data="chart5_1"
          :width="containerProps.width"
          :scrolledTo="containerProps.scrolledTo"
          :ariaLabel="containerProps.ariaLabel"
          :marginLeft="240"
          :marginBottom="20"
          :numberFormat="'Percent'"
          :showXAxis="false"
          :lineColor="'#a2a2a2'"
          :multiColors="false"
        ></app-lollipop-chart>
      </template>
    </app-chart-container>

    <div class="section-content">
      <p>
        One-third say they may choose to live in an age-restricted active adult community that could provide various amenities for a fee (32%) or a continuing-care community that could provide different levels of care as their personal needs change (32%). 
      </p>

      <!-- Call out -->
      <div class="call-out full-width">
        <div class="call-out-content">
          <h2>
            Recovery Funds Fuel Age-Friendly Community Investments
          </h2>
          <p>
            As communities emerge from the pandemic, local leaders are using recovery funds to support age-friendly housing policies and community developments. 
          </p>
          <ul>
            <li>
              <strong>Phoenix, Arizona:</strong> Phoenix is investing $2,000,000 to expand the city’s Memory Café program for senior center members and their caregivers. Memory Cafés stimulate participants through music therapy, art, and more. They are investing another $1,000,000 to support the restoration of the Justa Center, a building currently owned by the Area Agency on Aging (AAA) and serving vulnerable seniors experiencing homelessness. They city is also seeking to leverage funds with Maricopa County and the state to build a transitional or bridge housing complex next to the Justa Center.
            </li>
            <li>
              <strong>Madison, Wisconsin:</strong> Madison is using funds to expand access to high-speed internet for children and seniors and investing hundreds of thousands of dollars in virtual programming at senior centers.
            </li>
            <li>
              <strong>Houston, Texas:</strong> Houston is distributing American Rescue Plan Act (ARPA) rental relief funds in partnership with Harris County, and so far, have distributed $180 million in relief to cover back rent, including for older adults.
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- EXAMPLES -->
    <!-- EXAMPLES -->
    <!-- EXAMPLES -->
    <h2 class="examples-title">
      AARP In the Community
    </h2>
    <div class="examples-container">
      <app-example         
        v-for="example in examples"
        :key="example.community"
        :exampleData="example"
      >
      </app-example>  
    </div>
  </app-section-container>
</template>

<script>
import AppSectionContainer from '@/components/SectionContainer.vue';
import AppChartContainer from '@/components/charts/ChartContainer.vue';
import AppLollipopChart from '@/components/charts/LollipopChart.vue';
import AppExample from '@/components/reusables/Example.vue';

export default {
  name: 'Section 5',
  components: {
    AppSectionContainer,
    AppChartContainer,
    AppLollipopChart,
    AppExample
  },
  data() {
    return {
      examples: [
        {
          type: "Community Challenge Grantee",
          community: "Millinocket, ME",
          summary: "Residents of Millinocket are working to make their town a four-season, outdoor destination and age-friendly community. When the pandemic struck, they began thinking creatively for ways they could still achieve this goal despite health restrictions. AARP awarded them an <a href='https://www.aarp.org/livable-communities/community-challenge/' target='_blank' rel='noopener noreferrer'>AARP Community Challenge</a> grant for their project, the “Katahdin Snowdown,” which is a new event that encouraged residents to get outside again and learn about their town while following COVID-19 protocols. They hoped that the event would bring visitors to the town in what has traditionally been a slow season.",
          video: "6260667578001",
        },
        {
          type: "Community Challenge Grantee",
          community: "Charleston, SC",
          summary: "The West Ashley Greenway is a former rail line that was converted into a bicycle and pedestrian trail to connect businesses, parks, schools, municipal facilities and neighborhoods. Improving the greenway became a vital need as more retirees and families in the neighborhood became interested in nonmotorized transportation. To help slow down automotive traffic and draw attention to the safety needs of pedestrians and cyclists, AARP awarded them an <a href='https://www.aarp.org/livable-communities/community-challenge/' target='_blank' rel='noopener noreferrer'>AARP Community Challenge</a> grant to upgrade select intersections with artistic crosswalks.",
          video: "6147800507001",
        },
      ],
      features: [
        {
          name: "Well-maintained streets",
          icon: require("../../assets/images/icons/road.png"),
          alt: "Road icon",
          value: "80%",
        },
        {
          name: "Easy-to-read traffic signs",
          icon: require("../../assets/images/icons/stop.png"),
          alt: "Stop sign icon",
          value: "76%",
        },
        {
          name: "Accessible and safe streets",
          icon: require("../../assets/images/icons/person.png"),
          alt: "Person icon",
          value: "70%",
        },
        {
          name: "Enforced safe speed limits",
          icon: require("../../assets/images/icons/police.png"),
          alt: "Police icon",
          value: "69%",
        },
      ]
    }
  },
  computed: {
    chart5_1() {
      return this.selectChartData("5_1");
    },
  },
  methods: {
    selectChartData: function(chart) {
      return this.$store.getters.data.filter(d => d.Chart === chart);
    }
  }
}
</script>

<style lang="postcss" scoped>
.icon-container {
  @apply    
    border
    border-gray-200
    rounded
    my-8;

  h2 {
    @apply
      py-2
      px-4
      text-xl
      text-center
      border-b
      border-gray-200
      bg-gray-100;
  }

  .icon-grid {
    @apply
      gap-4
      grid
      grid-cols-2;

    @screen lg {
      @apply      
        grid-cols-4;
    }

    .icon {
      @apply
        mb-4;

      img {
        @apply
          mx-auto;
      }

      .caption {
        @apply
          mt-4
          px-8
          text-center;      
        
        .value {
          @apply
            font-bold
            text-aarp-red-dark
            text-4xl;
        }
      }
    }
  }
}
</style>