<template>
  <app-section-container
    sectionId="resources"
    sectionTitle="Resources"
  >
  
    <!-- Staying in place -->
    <!-- <div class="section-content">
      <h2>
        Learn more about staying in place:
      </h2>
      <ul>
        <li
          v-for="link in stayingLinks"
          :key="link.title"
        >
          <a 
            :href="link.url"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ link.title }}
          </a>
        </li>
      </ul>
    </div> -->

    <!-- Resources -->
    <!-- Resources -->
    <!-- Resources -->
    <div class="section-content">
      <div class="resource-list">
        <h2>
          Learn more about staying in place and ADUs:
        </h2>
        <ul>
          <li
            v-for="link in aduLinks"
            :key="link.title"
          >
            <a 
              :href="link.url"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ link.title }}
            </a>
          </li>
        </ul>
      </div>
      
      <!-- Resources -->
      <!-- Resources -->
      <!-- Resources -->
      <div class="resource-list">
        <h2>
          Learn more about age-friendly communities:
        </h2>
        <ul>
          <li
            v-for="link in afcLinks"
            :key="link.title"
          >
            <a 
              :href="link.url"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ link.title }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </app-section-container>
</template>

<script>
import AppSectionContainer from '@/components/SectionContainer.vue';

export default {
  name: 'Resources',
  components: {
    AppSectionContainer,
  },
  data() {
    return {
      // stayingLinks: [
      // ],
      aduLinks: [
        {
          title: "AARP HomeFit Guide",
          url: "https://www.aarp.org/livable-communities/housing/info-2020/homefit-guide.html"
        },
        {
          title: "All About Accessory Dwelling Units",
          url: "https://www.aarp.org/livable-communities/housing/info-2019/accessory-dwelling-units-adus.html"
        },
        // {
        //   title: "The ABCs of ADUs",
        //   url: "https://www.aarp.org/content/dam/aarp/livable-communities/livable-documents/documents-2019/ADU-guide-web-singles-071619.pdf"
        // },
        {
          title: "Future of Housing",
          url: "https://futureofhousing.aarp.org/"
        },
        // {
        //   title: "ADU Model State Act and Local Ordinance",
        //   url: "https://www.aarp.org/livable-communities/housing/info-2021/adu-model-state-act-and-local-ordinance.html"
        // },
      ],
      afcLinks: [
        {
          title: "Parks and Public Spaces for All Ages",
          url: "https://www.aarp.org/livable-communities/tool-kits-resources/info-2018/livable-parks-guide.html",
        },
        {
          title: "AARP Pop Up Placemaking Toolkit",
          url: "https://www.aarp.org/livable-communities/tool-kits-resources/info-2019/pop-up-tool-kit.html",
        },
        {
          title: "AARP Livability Index",
          url: "https://livabilityindex.aarp.org/",
        },
        {
          title: "AARP Livability Communities Map",
          url: "https://livablemap.aarp.org/",
        },
        {
          title: "AARP Network of Age-Friendly States and Communities",
          url: "https://www.aarp.org/livable-communities/network-age-friendly-communities/"
        }
      ]
    }
  }
}
</script>

<style lang="postcss" scoped>
.section-content {
  @apply
    grid
    grid-cols-1
    gap-12;
  
  @screen md {
    @apply
      grid-cols-2;
  }
/* 
  .resource-list {
    @apply
      px-4;
  } */
}
</style>