<template>
  <div class="loader-container">
    <transition-group
      name="fade"
      mode="out-in"
    >
      <div key="loader" class="loader" v-if="!dataError"></div>
      <div key="error" class="error" v-else>
        Error loading data. Please contact <a href="mailto:research@aarp.org">research@aarp.org</a> for assistance.
      </div>
    </transition-group>
  </div>
</template>

<script>
export default {
  name: "Loader",
  computed: {
    meta() {
      return this.$store.getters.meta;
    },
    dataError() {
      return this.$store.getters.dataError;
    }
  },
}
</script>

<style lang="postcss" scoped>
.loader-container {
  @apply
    flex
    bg-white

    /* Positioning */
    absolute
    top-0
    left-0
    z-50

    /* Dimensions */
    w-full 
    h-screen
    
    /* Alignment */
    items-center
    justify-center;

  .loader {
    @apply
      border-8
      border-gray-200
      w-24
      h-24;

    border-top-color: #2d3748;
    border-radius: 50%;
    animation: spin 1s ease-in-out infinite;
  }

  .error {
    @apply text-gray-800;

    a {
      @apply
        border-b
        border-dotted
        border-gray-600;

      transition: all 0.5s;

      &:hover {
        @apply border-transparent;        
      }
    }
  }
}

/* Animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Transitions */
/* Fade */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.25s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>