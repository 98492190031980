<template>
  <app-section-container
    sectionId="staying-in-place-2"
    trend="Many adults (49%) are open to alternative housing options that would better enable them to age independently. Around 60% of adults would consider options like accessory dwelling units (ADUs) or “in-law cottages” to be able to live near someone but maintain their own space, save money, or get help with daily activities."
  >
    <!-- Section content -->
    <div class="section-content">

      <!-- Right call out -->
      <div class="call-out">
        <div class="call-out-content">
          <h2>
            Accessory Dwelling Unit Usage on the Rise
          </h2>
          <ul>
            <li>
              The number of first-time ADU listings increased more rapidly from 2009 to 2019, growing at an average annual rate of 8.6%. Over this 10-year period, ADU listings as a percentage of total active listings rose from 3.5% to 6.6%.
            </li>
            <li>
              In 2019, 78,000 accessory units were either sold or rented, up from 36,000 in 2009.
            </li>
          </ul>

          <p class="source">
            <strong>Source:</strong> FreddieMac, <a href="http://www.freddiemac.com/research/insight/20200716_identifying_accessory_dwelling_units_from_real_estate.page" target="_blank" rel="noopener noreferrer"><em>Granny Flats, Garage Apartments, In-Law Suites: Identifying Accessory Dwelling Units from Real Estate Listing Descriptions Using Text Mining</em></a>.
          </p>
        </div>
      </div>

      <div class="section-text">
        <!-- Text -->
        <p>
          Most adults would consider leaving their current home to find one that would better enable them to age independently (all adults: 49%; 50-plus: 57%). Others would consider moving for options that:
        </p>
        
        <ul>
          <li>Cost less to maintain (All adults: 44%),</li>
          <li>Were smaller (All adults: 23%; and 50-plus: 29%), and</li>
          <li>Were physically easier to maintain (All adults: 38%; and 50-plus: 44%).</li>
        </ul>

        <p>
          In addition to around 60% of adults indicating they would consider living in ADUs, 62% of adults would consider building an ADU on their property to provide a place for a loved one needing care or a family member or friend who needs a home (54%).
        </p>
      </div>
    </div>

    <!-- CHART -->
    <app-chart-container
      title="What would make Americans consider moving into an ADU?"
      subtitle="Adults 18-49 versus adults 50-plus"
      maxWidthClass="max-w-4xl"
    >
      <template v-slot="containerProps">
        <app-dot-plot-chart
          :data="chart4_1"
          :width="containerProps.width"
          :scrolledTo="containerProps.scrolledTo"
          :ariaLabel="containerProps.ariaLabel"
          :marginLeft="260"
          :marginBottom="20"
          :numberFormat="'Percent'"
          :showXAxis="false"
          :specifiedXMax="1"
          :lineColor="'#a2a2a2'"
          :colors="['#5C0033','#EC1300']"
        ></app-dot-plot-chart>
      </template>
    </app-chart-container>

    <!-- ADU video -->
    <div class="section-content">
      <div class="adu-video-container">
        <h2>Meeting Housing Needs Today and Tomorrow: Creating an ADU to Age in Place</h2>

        <div class="adu-video">
          <iframe 
            class="brightcove-video"
            src='https://players.brightcove.net/3772599298001/ryhhcDoG_default/index.html?videoId=6107247446001' 
            allowfullscreen frameborder=0
          ></iframe>
        </div>
      </div>
    </div>

    <div class="section-content">
      <p>
        Of those who don’t currently have an ADU, homeowners are split on their reasons why. Around two-thirds (66%) say they don’t see the need for one, and nearly half (48%) say they don’t want to incur the cost to build one. Around a third (34%) of these adults say they do not want to go through the approval and permitting processes and 28% say the current zoning laws prohibit them from building this type of dwelling.
      </p>
    </div>

    <!-- EXAMPLES -->
    <!-- EXAMPLES -->
    <!-- EXAMPLES -->
    <h2 class="examples-title">
      AARP In the Community
    </h2>
    <div class="examples-container">
      <app-example         
        v-for="example in examples"
        :key="example.community"
        :exampleData="example"
      >
      </app-example>    
    </div>
  </app-section-container>
</template>

<script>
import AppSectionContainer from '@/components/SectionContainer.vue';
import AppChartContainer from '@/components/charts/ChartContainer.vue';
import AppDotPlotChart from '@/components/charts/DotPlotChart.vue';
import AppExample from '@/components/reusables/Example.vue';

export default {
  name: 'Section 4',
  components: {
    AppSectionContainer,
    AppChartContainer,
    AppDotPlotChart,
    AppExample
  },
  data() {
    return {
      examples: [
        {
          // type: "Community Aging in Place Grant",
          community: "Santa Cruz, CA",
          summary: "To reduce the number of older adults in Santa Cruz at risk of losing their homes due to the area’s extremely high cost of living, AARP awarded Habitat for Humanity Monterey Bay an <a href='https://www.aarp.org/livable-communities/community-challenge/' target='_blank' rel='noopener noreferrer'>AARP Community Challenge</a> grant to support the “My House My Home” program that builds ADUs so older homeowners can stay on their own property and earn rental income.",
          video: "6145032689001",
        },
        {
          // type: "Community Challenge Grant",
          community: "Louisville, KY",
          summary: "In the face of a housing shortage, AARP Kentucky took action through grassroots efforts in Louisville, an <a href='https://www.aarp.org/livable-communities/network-age-friendly-communities/' target='_blank' rel='noopener noreferrer'>AARP Age-Friendly Community</a>, that led to the passage of a local ordinance allowing the construction of ADUs. AARP awarded this community an <a href='https://www.aarp.org/livable-communities/community-challenge/' target='_blank' rel='noopener noreferrer'>AARP Community Challenge</a> grant to implement activities that help raise awareness and encourage adoption of ADUs.",
          // video: "NEED LINK NEED LINK NEED LINK",
          image: require('../../assets/images/louisville-kentucky.webp'),
          alt: "Photo of Louisville, KY"
        },
      ]
    }
  },
  computed: {
    chart4_1() {
      return this.selectChartData("4_1");
    },
  },
  methods: {
    selectChartData: function(chart) {
      return this.$store.getters.data.filter(d => d.Chart === chart);
    }
  }
}
</script>

<style lang="postcss" scoped>
.section-content {
  @apply
    flex
    flex-col;

  .section-text {
    @apply
      order-1;
  }

  .call-out {
    @apply
      order-2;
  }

  @screen lg {
    @apply
      block;
  }

  .adu-video-container {
    @apply
      rounded
      border
      border-gray-200;

    h2 {
      @apply
        p-2
        bg-gray-100
        text-center;
    }

    .adu-video {
      @apply
        p-4
        pt-0;
    }
  }
}
</style>