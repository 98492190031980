<template>
  <app-section-container
    sectionId="staying-in-place"
    sectionTitle="Staying in Place"
    trend="High numbers of Americans want “forever homes” — to stay in their current homes as they age. The percentage is even higher for adults age 50-plus, with more than three-quarters wanting to remain in their home as they age. But more aging-in-place modifications and technology are needed to make that possible."
  >
    <!-- Section content -->
    <div class="section-content">
      <p>
        Home ownership is high for older adults. Eight in 10 (79%) adults age 50 and older own their home and 51% of those homeowners have no mortgage. Comparatively, 50% of adults ages 18–49 own their home and 39% rent. More than three-quarters (77%) of younger homeowners are paying off a mortgage. 
      </p>

      <!-- Right call out -->
      <!-- <div class="call-out">
        <div class="call-out-content">
          <p>
            <strong>
              One-third (34%) of all adults say they need to make modifications to their current residence so that they or a loved one could continue living there in the event they needed physical assistance. 
            </strong>
          </p>
        </div>
      </div> -->

      <p>
        However, one-third (34%) of all adults say they need to make modifications to their current residence so that they or a loved one could continue living there in the event they needed physical assistance. 
      </p>

      <p>
        Top home modifications homeowners need to stay in their homes include:
      </p>
      <ul>
        <li>Bathroom modifications like adding grab bars and having no-step showers (79%)</li>
        <li>Making the home more accessible from the outside as well as within the home (71%)</li>
        <li>Emergency response systems (61%)</li>
        <li>Smart Home devices such as a voice activated home assistant or a doorbell camera and smart appliances (48%).</li>
      </ul>
    </div>

    <!-- Chart -->
    <app-chart-container
      title="Percent who need the following home modifications to continue living at their current residence"
      subtitle="In the event they need physical assistance"
      maxWidthClass="max-w-4xl"
    >
      <template v-slot="containerProps">
        <app-lollipop-chart
          :data="chart3_1"
          :width="containerProps.width"
          :scrolledTo="containerProps.scrolledTo"
          :ariaLabel="containerProps.ariaLabel"
          :marginLeft="180"
          :marginBottom="20"
          :numberFormat="'Percent'"
          :showXAxis="false"
          :lineColor="'#a2a2a2'"
          :multiColors="false"
        ></app-lollipop-chart>
      </template>
    </app-chart-container>
    
    <div class="section-content">
      <p>
        Having access to home modification contractors who are trustworthy and affordable is of particular importance to older adults (76%) compared to those ages 18–49 (59%). 
      </p>

      <p>
        Some would consider sharing their home in the future. The most preferred home-sharing option would be sharing their home with a family member (69%) or a friend (54%), and very few (6%) would consider sharing their home with a stranger.
      </p>
    </div>

    <!-- CHART -->
    <!-- CHART -->
    <!-- CHART -->
    <!-- Chart -->
    <app-chart-container
      title="Preferred home sharing options"
      subtitle="Among those who would share their home"
      maxWidthClass="max-w-4xl"
    >
      <template v-slot="containerProps">
        <app-lollipop-chart
          :data="chart3_2"
          :width="containerProps.width"
          :scrolledTo="containerProps.scrolledTo"
          :ariaLabel="containerProps.ariaLabel"
          :marginLeft="140"
          :marginBottom="20"
          :numberFormat="'Percent'"
          :showXAxis="false"
          :lineColor="'#a2a2a2'"
          :multiColors="false"
        ></app-lollipop-chart>
      </template>
    </app-chart-container> 
      
    <!-- EXAMPLES -->
    <!-- EXAMPLES -->
    <!-- EXAMPLES -->
    <h2 class="examples-title">
      AARP In the Community
    </h2>
    <div class="examples-container">
      <app-example         
        v-for="example in examples"
        :key="example.community"
        :exampleData="example"
      >
      </app-example>
    </div>

    <!-- LIST OF HOMEFIT -->
    <!-- LIST OF HOMEFIT -->
    <!-- LIST OF HOMEFIT -->
    <div class="section-content">
      <div class="homefit-modifications-container">
        <div class="homefit-title">
          <h2>Top 10 Age-Friendly Home Modifications</h2>
          <p>
            The <a href="https://www.aarp.org/livable-communities/housing/info-2020/homefit-guide.html" target="_blank" rel="noopener noreferrer">AARP HomeFit Guide</a> provides more than 100 tips and suggestions in a room-by-room format that are doable regardless of housing type. Click or tap on each of the icons below to view helpful tips for making various areas of a home age-friendly.
          </p>
        </div>
        <div class="homefit-icons">
          <div
            class="mod"
            v-for="(mod, i) in modifications"
            :key="mod.type"
            :class="{ selected: i === selectedMod }"
            @click="changeSelectedMod(i)"
          >
            <img 
              loading="lazy"
              :src="mod.icon"
              :alt="`${mod.type} icon`"
            >
            {{ mod.type }}
          </div>
        </div>

        <!-- Explanation -->
        <div class="mod-explanation">
          <p 
            style="margin-bottom: 0"
            v-html="selectedModText"
          >            
          </p>
        </div>
      </div>            
    </div>
  </app-section-container>
</template>

<script>
import AppSectionContainer from '@/components/SectionContainer.vue';
import AppChartContainer from '@/components/charts/ChartContainer.vue';
import AppLollipopChart from '@/components/charts/LollipopChart.vue';
import AppExample from '@/components/reusables/Example.vue';

export default {
  name: 'Section 3',
  components: {
    AppSectionContainer,
    AppChartContainer,
    AppLollipopChart,
    AppExample,
  },
  data() {
    return {
      examples: [
        {
          // type: "Community Aging in Place Grant",
          community: "Maine",
          summary: "In 2017, an aging-in-place initiative was launched in Bath, ME that led to <a href='https://www.mainehousing.org/programs-services/HomeImprovement/homeimprovementdetail/community-aging-in-place' target='_blank' rel='noopener noreferrer'>MaineHousing’s Community Aging in Place Grant</a>. This grant program offers state residents free home safety checks, minor maintenance repairs, and accessibility modifications to low-income older and disabled homeowners, all of which are easy solutions that can help them continue living independently in their homes.",
          // video: null,
          image: require('../../assets/images/bath-maine.webp'),
          alt: "Photo of Bath, ME"
        },
        {
          type: "Community Challenge Grantee",
          community: "St. Petersburg, FL",
          summary: "In 2021, AARP awarded the Florida Dream Center an <a href='https://www.aarp.org/livable-communities/community-challenge/info-2021/2021-grantees.html' target='_blank' rel='noopener noreferrer'>AARP Community Challenge</a> grant to help older adults with home repairs or improvements, such as adding grab bars, chair lifts, wheelchair ramps, staircase handrails and more, through door-to-door outreach to residents. The program will help reduce and address code violations, making it easier and safer for residents to continue living in their own homes.",
          // video: "NEED LINK NEED LINK NEED LINK",
          image: require('../../assets/images/stpetersburg-florida.webp'),
          alt: "Photo of St. Petersburg, FL"
        },
        // {
        //   // type: "Community Challenge Grant",
        //   community: "Medford, MO",
        //   summary: "AARP Livable Communities gave the Rogue Retreat a grant to buy and install furnishings and accessibility ramps at a former motel that is providing housing to homeless residents.",
        //   // video: "NEED LINK NEED LINK NEED LINK",
        // },
      ],
      modifications: [
        {
          type: "Exterior",
          icon: require('../../assets/images/icons/Exterior-icons.png'),
          text: "For exterior doorways, an overhang — to protect from rain, snow, sun — is a plus, as is a stoop or porch with room enough for sitting.",
        },
        {
          type: "Foyer",
          icon: require('../../assets/images/icons/Foyer-icon.png'),
          text: "It’s helpful to have a place to sit when putting on or taking off shoes. (A bench or chair is also handy for an adult who has to get shoes on and off of a toddler.) The space beneath a seat can be a spot for bins that hold shoes, slippers, hats, and gloves. Dog owners might use a bin or wall hooks for storing a leash and other pet accessories.",
        },
        {
          type: "Kitchen",
          icon: require('../../assets/images/icons/kitchen-icon.png'),
          text: "Lower-level cabinets with pullout drawers are easier to see and reach into than those with stationary shelving. Cabinet manufacturers and home improvement stores sell kits for adding sliding bins and shelves. Tip: Frequently used items are best stored between hip and shoulder height.",
        },
        {
          type: "Dining Room",
          icon: require('../../assets/images/icons/Dining-Room-icon.png'),
          text: "Dining table chairs with armrests provide support for getting into or up from the seat.",
        },
        {
          type: "Living Room",
          icon: require('../../assets/images/icons/Living-Room-icon.png'),
          text: "Secure area rugs to the floor with nonslip mats or double-sided tape, which will also keep the edges from curling up. ",
        },
        {
          type: "Hallways and Stairs",
          icon: require('../../assets/images/icons/hallway-stairway-icon.png'),
          text: "Placing plug-in or battery-operated dusk-to-dawn or motion sensor night-lights in hallways and near steps and staircases is a simple safety solution. When deciding where and how many night-lights to install, think of these low-wattage fixtures as lights for a residential runway.",
        },
        {
          type: "Bedroom",
          icon: require('../../assets/images/icons/Bedroom-icon.png'),
          text: "Create a designated spot — such as a basket, dresser top, drawer, or nightstand — for storing any electronics, charging cords, or computer accessories that end up in the bedroom.",
        },
        {
          type: "Closets",
          icon: require('../../assets/images/icons/Closets-icon.png'),
          text: "Adjustable shelving provides options for storing folded clothing or bed linens. Bins can hold socks and undergarments. High shelves can be used for seasonal items, such as a cold weather comforter.",
        },
        {
          type: "Bathroom",
          icon: require('../../assets/images/icons/Bathroom-icon.png'),
          text: "A comfort-height toilet is taller (17 to 19 inches from the floor to the seat top) and, depending on a person’s height, often easier to sit down on and get up from than a standard 15-inch version. A similar, more affordable solution is to use a portable toilet seat riser with support handles.",
        },
        {
          type: "Laundry Room",
          icon: require('../../assets/images/icons/Laundry-Room-icon.png'),
          text: "A wheeled laundry basket can eliminate or reduce the need to lift heavy baskets. A flexible basket with handles that can be gripped with one hand is easier and safer to carry than a rigid basket that’s awkward to hold and can block a person’s view of where their feet are stepping.",
        },
      ],
      selectedMod: 0,
    }
  },
  computed: {
    chart3_1() {
      return this.selectChartData("3_1");
    },
    chart3_2() {
      return this.selectChartData("3_2");
    },
    selectedModText() {
      const selectedMod = this.modifications[this.selectedMod];

      return `<strong>${selectedMod.type}:</strong> ${selectedMod.text}`;
    }
  },
  methods: {
    selectChartData: function(chart) {
      return this.$store.getters.data.filter(d => d.Chart === chart);
    },
    changeSelectedMod(idx) {
      this.selectedMod = idx;
    }
  }
}
</script>

<style lang="postcss" scoped>
.homefit-modifications-container {
  @apply
    rounded
    border
    border-gray-200;

  .homefit-title {
    h2 {
      @apply
        py-2
        px-4
        text-xl
        text-center
        border-b
        border-gray-200
        bg-gray-100;
    }

    p {
      @apply
        px-4;
    }
  }

  .homefit-icons {
    @apply
      grid
      grid-cols-4;

    img {
      @apply
        mx-auto;
    }

    @screen md {
      img {
        @apply
          w-1/2;
      }
    }

    @screen lg {
      @apply 
        /* gap-4 */
        grid-cols-5;
    }

    .mod {
      @apply
        p-2
        font-bold
        text-center
        transition-colors
        duration-300;

      &.selected {
        @apply
          bg-yellow-100;
      }

      &:hover {
        @apply
          bg-yellow-100
          cursor-pointer;
      }
    }
  }
}

.mod-explanation {
  @apply
    p-4
    font-normal
    bg-gray-100
    border-gray-200
    border-t;

  p {
    @apply
      mb-0;
  }
}
</style>