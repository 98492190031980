<template>
  <app-section-container
    sectionId="intro"
  >
    <!-- Section content -->
    <div class="section-content">

      <!-- Right call out -->
      <div class="call-out">
        <div class="call-out-content">
          <h2>
            Numbers of Older Adult Head of Households and Renters Soaring
          </h2>
          <ul>
            <li>
              Households headed by people age 65-plus are expected to grow from <strong>34 million to 48 million</strong> in the next 20 years.
            </li>
            <li>
              Nearly all the net household growth is anticipated to be by age 65-plus headed households during this time period (13.8 million out of 16.1 million).
            </li>
            <li>
              Yet, <strong>homeownership among adults age 65-plus is at historic lows</strong> (73%), and the number of renters among adults 65-plus is expected to grow from 7.4 million in 2020 to 12.9 million by 2040, with a particularly significant increase among Black renters age 65-plus (projected to double from 1.3 million to 2.6 million).
            </li>
          </ul>

          <p class="source">
            <strong>Source:</strong> The Urban Institute, <a href="https://www.urban.org/research/publication/future-headship-and-homeownership" target="_blank" rel="noopener noreferrer"><em>The Future of Headship and Homeownership</em></a>.
          </p>
        </div>
      </div>

      <!-- Text -->
      <div class="section-text">
        <p>
          The COVID-19 pandemic has altered how people think about their lives and homes – which has collided with exponential growth in the number of older heads of households and renters. These trends highlight the urgent need to rapidly increase and improve age-friendly and affordable community and housing options.
        </p>
        <p>
          AARP’s 2021 Home and Community Preferences survey found that over three-quarters (77%) of adults age 50 and older want to remain in their homes as they age. This desire is consistent across the lifespan with 63% of adults overall saying the same. The numbers of older adults wanting to remain in their homes as they age has remained relatively consistent for more than a decade and was not impacted by the pandemic. 
        </p>
        <p>
          Increasing the number of multigenerational households, providing more options like accessory dwelling units (ADUs) or “in-law units”, and encouraging renovations that support aging-in-place are all critical to support this desire.
        </p>
      </div>
    </div>
  
    <div class="section-content">
      <p class="data-note">
        Unless otherwise cited, all data used come from AARP's new 2021 Home and Community Preferences survey. The 2021 Home and Community Preferences Survey focuses on the needs and wants of all adults 18-plus with regards to housing, transportation, outdoor spaces, and social engagement and isolation. To learn more, please visit <a href="https://www.aarp.org/livablesurvey2021" target="_blank" rel="noopener noreferrer">www.aarp.org/livablesurvey2021</a>.
      </p>
    </div>
  </app-section-container>
</template>

<script>
import AppSectionContainer from '@/components/SectionContainer.vue';

export default {
  name: 'Introduction',
  components: {
    AppSectionContainer,
  },
  computed: {
    chart1_1() {
      return this.selectChartData("1_1");
    },
    chart1_2() {
      return this.selectChartData("1_2");
    }
  },
  methods: {
    selectChartData: function(chart) {
      return this.$store.getters.data.filter(d => d.Chart === chart);
    },
  }
}
</script>

<style lang="postcss" scoped>
.section-content {
  @apply
    flex
    flex-col;

  .section-text {
    @apply
      order-1;
  }

  .call-out {
    @apply
      order-2;
  }

  @screen lg {
    @apply
      block;
  }

  .data-note {
    @apply
      font-bold;

    a {
      @apply
        text-aarp-red-dark;
    }
  }
}
</style>