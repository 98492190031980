<template>
  <div>
    <svg
      :width="width"
      :height="height"
    >
    </svg>

    <!-- Tooltip -->
    <div class="tooltip"></div>
  </div>
</template>

<script>
export default {
  name: 'Column Chart',
  inject: ['$d3'],
  props: {
    data: {
      type: Array,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      default: 400
    },
    scrolledTo: {
      type: Boolean,
      required: true
    },
    marginTop: {
      type: Number,
      default: 20,
    },
    marginLeft: {
      type: Number,
      default: 20,
    },
    marginBottom: {
      type: Number,
      default: 40
    },
    transitionTime: {
      type: Number,
      default: 1000,
    },
    showYAxis: {
      type: Boolean,
      default: true
    },
    specifiedYMax: {
      type: Number,
      default: null
    },
    labelUnit: {
      type: String,
      default: null
    },
    columnColors: {
      type: Array,
      default: () => { return ["#EC1300", "#F75D59", "#5C0033", "#FFC19F", "#FF9B92"] },
    },
    multiColors: {
      type: Boolean,
      default: false,
    },
    numberFormat: {
      type: String,
      default: "Number"
    },
    ariaLabel: {
      type: String,
      required: true,
    }
  },
  computed: {
    margin() {
      return {
        top: this.marginTop,
        right: 20,
        left: this.marginLeft,
        bottom: this.marginBottom
      }
    },
    chartWidth() {
      return this.width - this.margin.right - this.margin.left;
    },
    chartHeight() {
      return this.height - this.margin.top - this.margin.bottom;
    },
    svg() {
      const svg = this.$d3.select(this.$el)
        .select("svg")
        .attr("role", "list")
        .attr("aria-label", this.ariaLabel)
        .append("g")
          .attr("transform", `translate(${this.margin.left}, ${this.margin.top})`);

      return svg;
    },
    tooltip() {
      const tooltip = this.$d3.select(this.$el)
        .select(".tooltip");

      return tooltip;
    },
    categories() {
      const categories = Array.from(new Set(this.data.map(row => row.Category)));
      return categories;
    },
    xAxis() {
      const xAxis = this.$d3.scaleBand()
        .rangeRound([0, this.chartWidth])
        .domain(this.categories)
        .padding(0.5);

      return xAxis;
    },
    xAxisCall() {
      const xAxisCall = this.$d3.axisBottom(this.xAxis)
        .tickSizeOuter(0);
        // .tickSize(0);

      return xAxisCall;
    },
    yAxis() {
      const yMax = this.specifiedYMax !== null ? this.specifiedYMax : this.$d3.max(this.data.map(row => row.Value));

      const yAxis = this.$d3.scaleLinear()
        .range([this.chartHeight, 0])
        .domain([0, yMax]);

      return yAxis;
    },
    yAxisCall() {
      const yAxisCall = this.$d3.axisLeft(this.yAxis)
        .tickSizeOuter(0)
        .tickFormat(this.numberFormatter);

      return yAxisCall;
    },
    colorScale() {
      const colorScale = this.$d3.scaleOrdinal()
        .domain(this.categories)
        .range(this.columnColors);

      return colorScale;
    },
    numberFormatter() {
      let numberFormatter;
      if (this.numberFormat === "Number") {
        numberFormatter = this.$d3.format(",.0f");
      } else if (this.numberFormat === "Percent") {
        numberFormatter = this.$d3.format(".0%");
      }

      return numberFormatter;
    }
  },
  methods: {
    drawChart() {
      // Draw columns
      this.svg.selectAll(".column")
        .data(this.data)
        .enter().append("rect")
          .attr("class", "column")
          .attr("x", d => this.xAxis(d.Category))
          .attr("width", this.xAxis.bandwidth())
          .attr("y", this.chartHeight)
          .attr("height", 0)
          .attr("role", "listitem")
          .attr("aria-label", d => `${d.Category}: ${this.numberFormatter(d.Value)}`)
          .style("fill", d => {
            if (this.multiColors) {
              return this.colorScale(d.Category);
            } else {
              return this.columnColors[0];
            }
          })
          .on("mouseover", (event, d) => {
            this.tooltip
              .style("visibility", "visible")
              .style("border-color", () => {
                if (this.multiColors) {
                  return this.colorScale(d.Category);
                } else {
                  return this.columnColors[0];
                }
              })
              .style("color", () => {
                if (this.multiColors) {
                  return this.colorScale(d.Category);
                } else {
                  return this.columnColors[0];
                }
              })
              // .style("color", this.colorScale(d.SubCategory))
              .html(() => `<p><strong>${d.Category}:</strong> ${this.numberFormatter(d.Value)}</p>`);
          })
          .on("mousemove", (event) => {
            // Set position of tooltip
            const bb = this.tooltip.node().getBoundingClientRect();

            this.tooltip
              .style("left", `${event.clientX - bb.width / 2}px`)
              .style("top", `${event.clientY - bb.height - 5}px`);
          })
          .on("mouseout", () => {
            // hide tooltip
            this.tooltip
              .style("visibility", "hidden")
              .html();
          });

      // Add axes
      this.svg.append("g")
        .attr("class", "x axis")
        .attr("transform", `translate(0, ${this.chartHeight})`)
        .attr("aria-hidden", "true")
        .call(this.xAxisCall);

      // Draw y-axis if required
      if (this.showYAxis) {
        this.svg.append("g")
          .attr("class", "y axis")
          .attr("aria-hidden", "true")
          .call(this.yAxisCall);
      }

      // Add data labels
      this.svg.selectAll(".label")
        .data(this.data)
        .enter().append("text")
          .attr("class", "label")
          .attr("x", d => this.xAxis(d.Category) + this.xAxis.bandwidth()/2)
          .attr("y", d => this.yAxis(d.Value))
          .attr("dy", "-0.5em")
          .attr("aria-hidden", "true")
          .style("opacity", 0)
          .style("fill", d => {
            if (this.multiColors) {
              return this.colorScale(d.Category);
            } else {
              return this.columnColors[0];
            }
          })
          .text(d => {
            if (this.labelUnit !== null) {
              return `${this.numberFormatter(d.Value)} ${this.labelUnit}`;
            } else {
              return this.numberFormatter(d.Value);
            }
          })

      // Animate if needed
      if (this.scrolledTo) {
        this.animate();
      }
    },
    animate() {
      // Columns
      this.svg.selectAll(".column")
        .transition()
          .duration(this.transitionTime)
          .delay((d, i) => this.transitionTime * i)
          .attr("y", d => this.yAxis(d.Value))
          .attr("height", d => this.yAxis(0) - this.yAxis(d.Value));

      // Data labels                  
      this.svg.selectAll(".label")
        .transition()
          .duration(this.transitionTime)
          .delay((d, i) => (i + 1) * this.transitionTime) // should become visible after bars animate
          .style("opacity", 1);
    },
    resize() {
      // Re-call x-axis
      this.svg.select(".x.axis")
        .call(this.xAxisCall);

      // Adjust column positions and widths
      this.svg.selectAll(".column")
        .attr("x", d => this.xAxis(d.Category))
        .attr("width", this.xAxis.bandwidth());

      // Adjust data labels
      this.svg.selectAll(".label")
        .attr("x", d => this.xAxis(d.Category) + this.xAxis.bandwidth()/2);
    }
  },
  mounted() {
    // Draw on mount
    this.drawChart();

    // Resize listener
    window.addEventListener("resize", this.resize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.resize);
  },
  watch: {
    scrolledTo(newValue) {
      // If scrolled to then...
      if (newValue) {
        // Trigger animations if not transitioned
        if (!this.transitioned) {
          this.animate();
        }
      }
    }
  }
}
</script>

<style lang="postcss" scoped>
svg {
  :deep() {
    @apply
      font-sans;
    /* font-family: 'ff-dagny-web-pro', sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* text {
      @apply text-white;
    } */

    /* .column {
      fill: white;
    } */

    .label {
      /* fill: white; */
      text-anchor: middle;
    }

    .axis {
      @apply
        font-sans;

      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      &.x {
        .tick {
          line {
            @apply invisible;
          }
          
          text {
            @apply text-sm;
          }
        }
      }
    }
  }
}

.tooltip {
  @apply
    fixed
    pointer-events-none
    max-w-sm
    invisible
    
    /* General styling */
    bg-white
    border
    border-2
    border-solid
    rounded
    p-2
    text-left
    text-sm
    shadow
    text-gray-800;
}
</style>