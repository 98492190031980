<template>
  <div class="footer">
    <div class="container">
      
      <div class="links">
        <!-- Links -->
        <div
          v-for="link in links"
          :key="link.title"
        >
          <h1>{{ link.title }}</h1>
          <p>
            {{ link.description }}
          </p>
          <p>
            To learn more, please visit <a :href="link.url" target="_blank" rel="noopener noreferrer">{{ link.urlDisplay }}</a>.
          </p>
        </div>
      </div>

      <!-- Logo and copyright -->
      <div class="copyright-logo">
        <div>
          <p>
            &copy; 2021 AARP<br>601 E St NW, Washington, District of Columbia 20004 US
          </p>
        </div>

        <!-- Logo -->
        <div>
          <a href="https://www.aarp.org/" target="_blank" rel="noopener noreferrer">
            <img src="../../assets/images/AARP_Logo_2020_Red.png" alt="AARP logo">
          </a> 
        </div>
      </div>
    </div>
    
  </div>
  
  <!-- TOS and Privacy Policy -->
  <div class="tos-privacy">
    <div class="container">
      <a 
        v-for="link in tosLinks"
        :key="link.title"
        :href="link.url"
        target="_blank"
        rel="noopener noreferrer"
      >
        {{ link.title }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      links: [
        {
          title: "2021 Home and Community Preferences Survey",
          description: 'The 2021 Home and Community Preferences Survey focuses on the needs and wants of all adults 18-plus with regards to housing, transportation, outdoor spaces, and social engagement and isolation.',
          url: "https://www.aarp.org/livablesurvey2021",
          urlDisplay: "www.aarp.org/livablesurvey2021"
        },
        {
          title: "AARP Livable Communities",
          description: "AARP works with elected officials, local leaders, policymakers, planners and citizen activists to help towns, cities, counties and states create age-friendly, livable communities for people of all ages.",
          url: "https://www.aarp.org/livable-communities",
          urlDisplay: "www.aarp.org/livable-communities"
        },
        {
          title: "AARP Livability Index",
          description: "The AARP Livability Index scores neighborhoods and communities across the U.S. for the services and amenities that impact your life the most. The AARP Public Policy Institute developed the Livability Index as a web-based tool to measure community livability.",
          url: "https://www.aarp.org/livabilityindex",
          urlDisplay: "www.aarp.org/livabilityindex"
        },
      ],
      tosLinks: [
        {
          title: "Copyright Information",
          url: "https://www.aarp.org/about-aarp/aarp-website-copyright-information/"
        },
        {
          title: "Privacy Policy",
          url: "https://www.aarp.org/about-aarp/privacy-policy/"
        },
        {
          title: "Terms of Service",
          url: "https://www.aarp.org/about-aarp/terms-of-service/"
        },
        {
          title: "Your Privacy Choices",
          url: "https://help.aarp.org/s/ccpa-request-page"
        }
      ]
    }
  }
}
</script>

<style lang="postcss" scoped>
.footer {
  @apply
    w-full
    bg-gray-100;
  
  .container {
    @apply
      py-8;

    .links {
      @apply
        flex
        flex-col;

      @screen lg {
        @apply
          flex-row
          justify-between;
      }

      div {
        @apply
          flex-1
          p-4;

        h1 {
          @apply
            text-lg
            font-bold
            mb-4;
        }

        p {
          @apply
            mb-4
            text-sm;

          &:last-child {
            @apply
              mb-0;
          }
        }

        a {
          @apply
            text-aarp-red-dark
            font-bold
            border-b
            border-aarp-red-dark;

          transition: border-color 300ms;

          &:hover {
            @apply
              border-transparent;
          }
        }
      }
    }

  }

  .copyright-logo {
    @apply
      pt-4
      flex
      flex-col
      text-center
      text-sm;
    
    @screen md {
      @apply
        flex-row
        justify-between
        items-end
        text-left;
    }

    div {
      @apply
        p-4;

      img {
        @apply
          mx-auto;
      }
    }
  }
}

.tos-privacy {
  @apply
    bg-aarp-black
    text-white;

  .container {
    @apply
      p-4
      text-left
      flex
      flex-col;

    @screen lg {
      @apply
        block
        text-center;
    }

    a {
      @apply
        px-4
        py-2;

      @screen lg {
        @apply
          px-0
          py-0
          mx-4;
      }
    }
  }
}
</style>