import { createApp } from 'vue'
import App from './App.vue'
import './assets/css/tailwind.css'

// Bring in d3
import d3 from '@/assets/d3/d3';

// Store
import store from './store'

// Global methods
const scrollToAnchor = (target, event) => {
  event.preventDefault();

  // Get anchor target
  const anchor = document.querySelector(target),
        anchorOffsetTop = anchor.offsetTop;

  const header = document.querySelector(".header"),
        headerRect = header.getBoundingClientRect(),
        headerHeight = headerRect.height;

  window.scrollTo({
    top: anchorOffsetTop - headerHeight,
    behavior: 'smooth',
  });
}

// Create app with global methods
const app = createApp(App);

app.config.globalProperties.$scrollToAnchor = scrollToAnchor;

// Mount
app.use(store)
  .provide('$d3', d3)
  .mount('#app')
