<template>
  <div>
    <!-- Filter -->
    <category-filter
      v-if="hasSubCategories"
      :categories="categories"
      :transitioned="transitioned"
      @category-filter-changed="handleCategoryFilterChange"
    >
    </category-filter>

    <!-- Chart -->
    <svg
      :width="width"
      :height="height"
    >
    </svg>

    <!-- Tooltip -->
    <div class="tooltip"></div>
  </div>
</template>

<script>
import CategoryFilter from '@/components/charts/CategoryFilter.vue';

export default {
  inject: ['$d3'],
  name: "Lollipop Chart",
  components: {
    CategoryFilter,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      default: 400
    },
    scrolledTo: {
      type: Boolean,
      required: true
    },
    marginTop: {
      type: Number,
      default: 20,
    },
    marginLeft: {
      type: Number,
      default: 40,
    },
    marginBottom: {
      type: Number,
      default: 40
    },
    marginRight: {
      type: Number,
      default: 45,
    },
    transitionTime: {
      type: Number,
      default: 1000,
    },
    showXAxis: {
      type: Boolean,
      default: true
    },
    specifiedXMax: {
      type: Number,
      default: null
    },
    sortDirection: {
      type: String,
      default: null,
    },
    labelUnit: {
      type: String,
      default: null
    },
    dotRadius: {
      type: Number,
      default: 5
    },
    dotColors: {
      type: Array,
      default: () => { return ["#EC1300", "#F75D59", "#5C0033", "#FFC19F", "#FF9B92"] },
    },
    multiColors: {
      type: Boolean,
      default: false,
    },
    lineColor: {
      type: String,
      default: "black"
    },
    numberFormat: {
      type: String,
      default: "Number"
    },
    ariaLabel: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      selectedCategory: null,
      transitioned: false,
    }
  },
  computed: {
    svg() {
      const svg = this.$d3.select(this.$el).select("svg")
        .attr("role", "list")
        .attr("aria-label", this.ariaLabel)
        .append("g");
          // .attr("transform", `translate(${this.margin.left}, ${this.margin.top})`);

      return svg;
    },
    tooltip() {
      const tooltip = this.$d3.select(this.$el)
        .select(".tooltip");

      return tooltip;
    },
    categories() {
      const categories = Array.from(new Set(this.data.map(row => row.Category)));

      return categories;
    },
    hasSubCategories() {
      const subCategories = Array.from(new Set(this.data.map(row => row.SubCategory)));
      const hasSubCategories = subCategories.length > 0 && (subCategories[0].length > 0) ? true : false;
      
      return hasSubCategories;
    },
    chartData() {
      let chartData = [];

      // Filter if necessary
      if (this.hasSubCategories) {
        chartData = this.data.filter(d => d.Category === this.selectedCategory);
      } else {
        chartData = this.data;
      }

      // Sort if necessary
      if (this.sortDirection !== null) {
        chartData = this.sortData(chartData);
        return chartData;
      } else {
        return chartData;
      }
    },
    yAxisCategories() {
      // depends on whether subCategories exists or not
      const yAxisCategories = this.hasSubCategories ? Array.from(new Set(this.chartData.map(row => row.SubCategory))) : Array.from(new Set(this.chartData.map(row => row.Category)));

      return yAxisCategories;
    },
    dimensions() {
      // Add it as text for categories on the svg to calculate BBox widths
      // const longestCategoryBoxes = this.svg.selectAll(".longest-category-box")
      //   .data(this.yAxisCategories)
      //   .enter().append("text")
      //     .attr("class", "longest-category-box")
      //     .text(d => d);

      // let longestCategoryBoxWidth = 0;
      // longestCategoryBoxes.each(function() {
      //   const boxWidth = this.getBoundingClientRect().width;
      //   console.log(boxWidth, this);

      //   if (boxWidth > longestCategoryBoxWidth) {
      //     longestCategoryBoxWidth = boxWidth;
      //   }
      // });

      // Remove the box
      // this.svg.selectAll(".longest-category-box")
      //   .remove();

      const margin = {
        top: this.marginTop,
        right: this.marginRight,
        left: this.marginLeft,
        bottom: this.marginBottom
      };

      return {
        margin: margin,
        chart: {
          width: this.width - margin.right - margin.left,
          height: this.height - margin.top - margin.bottom
        }
      }
    },
    xAxis() {
      const xMax = this.specifiedXMax !== null ? this.specifiedXMax : this.$d3.max(this.data.map(row => row.Value));

      const xAxis = this.$d3.scaleLinear()
        .range([0, this.dimensions.chart.width])
        .domain([0, xMax]);

      return xAxis;
    },
    xAxisCall() {
      const xAxisCall = this.$d3.axisBottom(this.xAxis)
        .tickSizeOuter(0)
        .tickFormat(this.numberFormatter);

      return xAxisCall;
    },
    yAxis() {
      const yAxis = this.$d3.scaleBand()
        .rangeRound([0, this.dimensions.chart.height])
        .domain(this.yAxisCategories)
        .padding(0.5);

      return yAxis;
    },
    yAxisCall() {
      const yAxisCall = this.$d3.axisLeft(this.yAxis)
        .tickSizeOuter(0);
        // .tickSize(0);

      return yAxisCall;
    },
    colorScale() {
      const colorScale = this.$d3.scaleOrdinal()
        .domain(this.categories)
        .range(this.dotColors);

      return colorScale;
    },
    numberFormatter() {
      let numberFormatter;
      if (this.numberFormat === "Number") {
        numberFormatter = this.$d3.format(",.0f");
      } else if (this.numberFormat === "Percent") {
        numberFormatter = this.$d3.format(".0%");
      }

      return numberFormatter;
    }
  },
  methods: {
    drawChart() {      
      // Set svg transform
      this.svg
        .attr("transform", `translate(${this.dimensions.margin.left}, ${this.dimensions.margin.top})`);

      // Draw lines
      this.svg.selectAll(".line")
        .data(this.chartData)
        .enter().append("line")
          .attr("class", "line")
          .attr("x1", this.xAxis(0))
          .attr("x2", this.xAxis(0))
          .attr("y1", d => { 
            if (this.hasSubCategories) {
              return this.yAxis(d.SubCategory) + this.yAxis.bandwidth()/2;
            } else {
              return this.yAxis(d.Category) + this.yAxis.bandwidth()/2;
            }
          })
          .attr("y2", d => { 
            if (this.hasSubCategories) {
              return this.yAxis(d.SubCategory) + this.yAxis.bandwidth()/2;
            } else {
              return this.yAxis(d.Category) + this.yAxis.bandwidth()/2;
            }
          })
          .style("stroke", this.lineColor);

      // Draw dots
      this.svg.selectAll(".dot")
        .data(this.chartData)
        .enter().append("circle")
          .attr("class", "dot")
          .attr("cx", d => this.xAxis(d.Value))
          .attr("cy", d => { 
            if (this.hasSubCategories) {
              return this.yAxis(d.SubCategory) + this.yAxis.bandwidth()/2;
            } else {
              return this.yAxis(d.Category) + this.yAxis.bandwidth()/2;
            }
          })
          .attr("r", 0)
          .style("fill", d => {
            if (this.multiColors) {
              return this.colorScale(d.Category);
            } else {
              return this.dotColors[0];
            }
          })
          .attr("role", "listitem")
          .attr("aria-label", d =>  {
            if (this.hasSubCategories) {
              return `${d.Category}, ${d.SubCategory}: ${this.numberFormatter(d.Value)}`
            } else {
              return `${d.Category}: ${this.numberFormatter(d.Value)}`
            }
          })
          .on("mouseover", (event, d) => {
            this.tooltip
              .style("visibility", "visible")
              .style("border-color", d => {
                if (this.multiColors) {
                  return this.colorScale(d.Category);
                } else {
                  return this.dotColors[0];
                }
              })
              .style("color", d => {
                if (this.multiColors) {
                  return this.colorScale(d.Category);
                } else {
                  return this.dotColors[0];
                }
              })
              // .style("color", this.colorScale(d.SubCategory))
              .html(() => {
                let html;

                if (this.hasSubCategories) {
                  html = `<p><strong>${d.Category}</strong></p><p><em>${d.SubCategory}: ${this.numberFormatter(d.Value)}</em></p>`;
                } else {
                  html = `<p><strong>${d.Category}:</strong> ${this.numberFormatter(d.Value)}</p>`;
                }

                return html;
              });
          })
          .on("mousemove", (event) => {
            // Set position of tooltip
            const bb = this.tooltip.node().getBoundingClientRect();

            this.tooltip
              .style("left", `${event.clientX - bb.width / 2}px`)
              .style("top", `${event.clientY - bb.height - 5}px`);
          })
          .on("mouseout", () => {
            // hide tooltip
            this.tooltip
              .style("visibility", "hidden")
              .html();
          });

      // Add axes
      this.svg.append("g")
        .attr("class", "y axis")
        .attr("aria-hidden", "true")
        .call(this.yAxisCall);

      // Draw x-axis if required
      if (this.showXAxis) {
        this.svg.append("g")
          .attr("class", "x axis")
          .attr("transform", `translate(0, ${this.dimensions.chart.height})`)
          .attr("aria-hidden", "true")
          .call(this.xAxisCall);
      }

      // Add data labels
      this.svg.selectAll(".label")
        .data(this.chartData)
        .enter().append("text")
          .attr("class", "label")
          .attr("x", d => this.xAxis(d.Value))
          .attr("dx", 2 * this.dotRadius)
          .attr("y", d => { 
            if (this.hasSubCategories) {
              return this.yAxis(d.SubCategory) + this.yAxis.bandwidth()/2;
            } else {
              return this.yAxis(d.Category) + this.yAxis.bandwidth()/2;
            }
          })
          .attr("dy", "0.3em")
          .attr("aria-hidden", "true")
          .style("opacity", 0)
          .style("fill", d => {
            if (this.multiColors) {
              return this.colorScale(d.Category);
            } else {
              return this.dotColors[0];
            }
          })
          .text(d => {
            if (this.labelUnit !== null) {
              return `${this.numberFormatter(d.Value)} ${this.labelUnit}`;
            } else {
              return this.numberFormatter(d.Value);
            }
          })

      // Animate if needed
      if (this.scrolledTo) {
        this.animate();
      }
    },
    animate() {
      // lines and dots
      this.svg.selectAll(".line")
        .transition()
          .duration(this.transitionTime)
          .attr("x2", d => this.xAxis(d.Value))

      // Dots
      this.svg.selectAll(".dot")
        .transition()
          .duration(this.transitionTime)
          .delay(this.transitionTime)
          .attr("r", this.dotRadius)
          .on("end", () => {
            // Data labels                  
            this.svg.selectAll(".label")
              .transition()
                .duration(this.transitionTime)
                .style("opacity", 1)
                .on("end", () => {
                  // Mark transitioned
                  this.transitioned = true;
                });            
          });
    },
    resize() {      
      if (this.showXAxis) {
        // Re-call x-axis
        this.svg.select(".x.axis")
          .call(this.xAxisCall);
      }
  
      // Adjust dots
      this.svg.selectAll(".dot")
        .attr("cx", d => this.xAxis(d.Value));

      // Adjust data labels
      this.svg.selectAll(".label")
        .attr("x", d => this.xAxis(d.Value));

      // Only if scrolled to
      if (this.scrolledTo) {        
        // Adjust lines
        this.svg.selectAll(".line")
          .interrupt();

        this.svg.selectAll(".line")
          .attr("x2", d => this.xAxis(d.Value));
      }
    },
    changeData() {
      // Change data and interrupt any existing transitions just in case
      this.svg.selectAll(".dot, .label, .line")
        .data(this.chartData)
        .interrupt();

      if (this.scrolledTo) {
        // Adjust dots
        this.svg.selectAll(".dot")
          .data(this.chartData)
          .transition()
            .duration(this.transitionTime)
            .attr("cx", d => this.xAxis(d.Value))
            .attr("r", this.dotRadius);
  
        // Adjust data labels
        this.svg.selectAll(".label")
          .data(this.chartData)
          .transition()
            .duration(this.transitionTime)
            .attr("x", d => this.xAxis(d.Value))
            .text(d => {
              if (this.labelUnit !== null) {
                return `${this.numberFormatter(d.Value)} ${this.labelUnit}`;
              } else {
                return this.numberFormatter(d.Value);
              }
            })
  
        // Adjust lines
        this.svg.selectAll(".line")
          .data(this.chartData)
          .transition()
            .duration(this.transitionTime)
            .attr("x2", d => this.xAxis(d.Value));
  
        // Adjust y-axis
        this.svg.select(".y.axis")
          .transition()
            .duration(this.transitionTime)
            .call(this.yAxisCall);
      }
    }, 
    handleCategoryFilterChange(category) {
      // Update the category
      this.selectedCategory = category;
    },
    sortData(data) {
      if (this.sortDirection === "Descending") {
        return data.sort((a, b) => b.Value - a.Value);
      } else if (this.sortDirection === "Ascending") {
        return data.sort((a, b) => a.Value - b.Value);
      }
    },
  },
  beforeMount() {
    // Before mount, determine if there is a selected category
    this.hasSubCategories ? this.handleCategoryFilterChange(this.categories[0]) : null;
  },
  mounted() {
    // Draw on mount
    this.drawChart();

    // Resize listener
    window.addEventListener("resize", this.resize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.resize);
  },
  watch: {
    scrolledTo: function(newValue) {
      // If scrolled to then...
      if (newValue) {
        // Trigger animations if not transitioned
        if (!this.transitioned) {
          this.animate();
        }
      }
    }, 
    selectedCategory: function(newValue, oldValue) {
      newValue !== oldValue ? this.changeData() : null;
    }   
  }
}
</script>

<style lang="postcss" scoped>
svg {
  :deep() {
    @apply
        font-sans;

    text {
      @apply text-black;
    }

    .line {
      stroke-width: 1;
      shape-rendering: crispEdges;
    }

    .label {
      text-anchor: start;
    }

    .axis {
      @apply
        font-sans;

      &.y {
        .tick {
          line {
            @apply invisible;
          }
          
          text {
            @apply text-sm;
          }
        }
      }
    }

    .longest-category-box {
      @apply text-sm;
    }
  }
}

.tooltip {
  @apply
    fixed
    pointer-events-none
    invisible
    max-w-sm
    bg-white
    border
    border-2
    border-solid
    rounded
    p-2
    text-left
    text-sm
    shadow
    text-gray-800;
}
</style>