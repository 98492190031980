import { createStore } from 'vuex'
import axios from 'axios';
import { tsvParse } from 'd3';

import Data from '@/assets/data/data.tsv';

export default createStore({
  state: {
    data: null,
    dataLoaded: false,
    dataError: false,
  },
  getters: {
    data: state => state.data,
    dataLoaded: state => state.dataLoaded,
    dataError: state => state.dataError,
  },
  mutations: {
    loadData(state, data) {
      state.data = data;
      state.dataLoaded = true;
    },
    showDataError(state) {
      state.dataError = true;
    },
  },
  actions: {
    loadData({ commit }) {
      // Load chart data
      axios.get(Data)
        .then(data => {

          // Parse data
          const parsedData = tsvParse(data.data);

          // Convert to numeric
          parsedData.forEach(row => row.Value = +row.Value);

          // Send to store
          commit('loadData', parsedData);
        })
        .catch(() => {
          commit('showDataError');
        });
    }
  },
  modules: {
  }
})
