<template>
  <div 
    class="header"
    ref="header"
  >
    <div class="container">
      <!-- AARP Logo -->
      <div class="logo">
        <a href="https://www.aarp.org/" target="_blank" rel="noopener noreferrer">
          <img src="../assets/images/AARP_Logo_2020_Red.png" alt="AARP logo">
        </a>        
        <!-- 2021 Home and Community Preferences Survey: Thematic Findings -->
      </div>

      <!-- Nav -->
      <!-- Mobile (dropdown) -->
      <div class="navigation mobile">
        <div
          class="menu-icon"
          @click="toggleMobileMenu()"
        >
          Menu ☰
        </div>
        <div 
          class="dropdown"
          :class="{ 'open': mobileMenuOpen }"
        >
          <div class="container">
            <a
              v-for="section in sections"
              :key="section.title"
              :href="section.anchor"
              @click="mobileMenuClick(section.anchor, $event)"
            >
              {{ section.title }}
            </a>
          </div>
        </div>
      </div>

      <!-- Nav -->
      <!-- Sections -->
      <div class="navigation desktop">
        <a
          v-for="section in sections"
          :key="section.title"
          :href="section.anchor"
          @click="$scrollToAnchor(section.anchor, $event)"
        >
          {{ section.title }}
        </a>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Header',
  // inject: ['$scrollToAnchor'],
  data() {
    return {
      sections: [
        {
          title: "Introduction",
          anchor: "#intro"
        },
        {
          title: "Home as a Hub",
          anchor: "#home-as-a-hub",
        },
        {
          title: "Multigenerational Households",
          anchor: "#multigenerational-households",
        },
        {
          title: "Staying in Place",
          anchor: "#staying-in-place",
        },
        {
          title: "Community",
          anchor: "#community",
        },
      ],
      mobileMenuOpen: false,
    }
  },
  methods: {
    openMobileMenu() {
      this.mobileMenuOpen = true;
    },
    closeMobileMenu() {
      this.mobileMenuOpen = false;
    },
    toggleMobileMenu() {
      this.mobileMenuOpen ? this.closeMobileMenu() : this.openMobileMenu();
    },
    mobileMenuClick(target, event, el) {
      // Close mobile menu
      this.closeMobileMenu();

      // Scroll to target
      this.$scrollToAnchor(target, event, el);
    }
  }
}
</script>

<style lang="postcss" scoped>
.header {
  @apply
    fixed
    w-full
    h-14
    top-0
    left-0
    /* py-2 */
    z-50
    bg-white
    border-b
    border-aarp-gray
    font-sans
    flex
    flex-row
    items-center;

    .container {
      @apply
        mx-0
        w-full
        flex
        flex-row
        justify-between
        items-stretch;

      .logo {
        @apply
          flex
          flex-row
          items-center
          font-bold
          p-2;

        /* img {
          @apply
            mr-4
        } */
      }

      .navigation {

        /* Mobile nav */
        &.mobile {
          @apply
            flex
            items-center
            font-bold;

          @screen lg {
            @apply
              hidden;
          }

          .menu-icon {
            @apply
              flex
              items-center
              h-full;

            &:hover {
              @apply
                cursor-pointer;
            }
          }

          .dropdown {
            @apply
              hidden
              fixed
              top-0
              left-0
              mt-14
              w-full
              bg-white;

            &.open {
              @apply
                block;
            }

            .container {
              @apply
                flex-col;

              a {
                @apply
                  block
                  p-2
                  border
                  border-b-0
                  border-gray-200;

                &:hover {
                  @apply
                    text-aarp-red-dark;
                }

                &:first-child {
                  @apply
                    border-t-0;
                }

                &:last-child {
                  @apply
                    border-b;
                }
              }
            }

          }

        }

        /* Desktop nav */
        &.desktop {
          @apply
            hidden;

          @screen lg {
            @apply
              flex
              flex-row;          
          }

          a {
            @apply
              flex
              flex-row
              items-center
              mx-4
              font-bold
              transition-colors
              duration-300
              h-full;

            &:first-child {
              @apply
                ml-0;
            }

            &:last-child {
              @apply
                mr-0;
            }

            &:hover {
              @apply
                text-aarp-red-dark;
            }
          }
        }
      }
    }
    
}
</style>